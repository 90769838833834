import { makeStyles, createStyles, Typography, Link } from "@material-ui/core";
import React from "react";
import Helmet from "react-helmet";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2, 6),
      "& p.indent": { marginLeft: theme.spacing(4) },
    },
  })
);

const Terms: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet
        title="Post Reality - Terms"
        meta={[
          {
            name: "description",
            content:
              "The future of presentations with augmented reality. Turn regular posters and presentations into engaging augmented reality experiences in minutes.",
          },
          {
            name: "keywords",
            content:
              "ar presentation, augmented presentation, ar, editor, presentation",
          },
        ]}
      />
      <div className={classes.root}>
        <Typography variant="h3" paragraph>
          Terms of Service
        </Typography>
        <Typography paragraph>
          The following Terms of Service apply to your use of all websites,
          mobile applications, and other products and services owned,
          controlled, operated or hosted (whether now or in the future) by
          KreatAR, LLC, Post Reality or any of their corporate affiliates
          (collectively the "KreatAR Sites").
        </Typography>
        <Typography paragraph style={{ fontWeight: 500 }}>
          Last Updated: July 8, 2019
        </Typography>
        <Typography paragraph>
          KreatAR, LLC ("KreatAR" or "we") is a Nevada corporation that: (i)
          creates, produces and distributes entertainment Content (as defined
          below); (ii) offers users the opportunity to participate in community
          media applications by submitting (e.g., "uploading") User Materials
          (as defined below) to the KreatAR Sites so that such User Materials
          can be distributed to the general public via the KreatAR Sites and the
          KreatAR Services (as defined below).
        </Typography>
        <Typography paragraph>
          These Terms of Service (this "Agreement") set forth certain terms and
          conditions of the legal contract between you and KreatAR with respect
          to your use of the KreatAR Sites and KreatAR Services, whether you are
          an unregistered visitor ("Visitor"), a free account holder
          ("Registered Member"), or a paying subscriber ("Paid Subscriber"). By
          using any of the KreatAR Sites or KreatAR Services, you agree to be
          also bound by the KreatAR Privacy Policy (click here) and the Legal
          Notices (click here). In addition, if you make any purchases on any of
          the KreatAR Sites, you agree to be also bound by the KreatAR Terms of
          Sale (click here).
        </Typography>
        <Typography paragraph>
          1. CERTAIN DEFINITIONS: As used herein, the following capitalized
          terms shall have the meanings set forth as follows: "Content" means
          and includes, without limitation, photos, images, artwork, graphics,
          videos, audios, animations, text, editorials, comments, writings,
          personal information, music, liner notes, lyrics, and other elements
          and materials, in any media or format now known or hereinafter
          devised, whether physical, electronic, digital, analog or otherwise.
          "Collaborative Content" means any customized Content that results from
          Users incorporating User Materials into KreatAR Content (as defined
          below) as the functionality of the KreatAR Sites and KreatAR Services
          may allow and enable from time to time. "KreatAR Content" means any
          and all Content (excluding User Materials) made available on the
          KreatAR Sites and/or through the KreatAR Services, including Content
          owned or controlled by KreatAR, Post Reality and/or its corporate
          affiliates, as well as Content licensed or otherwise provided to
          KreatAR by Licensors/Suppliers (as defined below). "KreatAR Services"
          means any and all services, tools, Software (as defined in Section
          23), applications, and functionalities as may be provided on the
          KreatAR Sites from time to time. "Licensors/Suppliers" means any and
          all third parties that make available to KreatAR (pursuant to a
          license or otherwise) their proprietary Content, products or services
          for use, deployment, display, performance, and/or distribution via the
          KreatAR Sites. For the avoidance of doubt, the term
          "Licensors/Suppliers" includes third parties that place, display, or
          distribute their advertising, promotional, or sponsorship Content on
          the KreatAR Sites. "Trademarks" means any and all marks, brands,
          logos, designs, character names, location names, slogans, catch words
          and phrases, business names, trade names, trade dress, packaging
          designs, label designs, webpage layouts, look and feel, and other
          indicia of source, origin or identification, owned or controlled by
          KreatAR, its corporate affiliates, and/or Licensors/Suppliers. Any and
          all Trademarks owned or controlled by KreatAR and/or its corporate
          affiliates, including, without limitation, the KreatAR name and logos
          and characters, and the layout and design of the KreatAR Sites, are
          referred to hereinafter as "KreatAR Trademarks"; and any and all
          Trademarks owned or controlled by Licensors/Suppliers, including,
          without limitation, those listed in the legal notices referenced in
          Section 2 below, are referred to hereinafter as "Licensors/Suppliers
          Trademarks." "User Materials" means Content which users upload,
          submit, record, stream, distribute, or otherwise make available via
          the KreatAR Sites and/or through the KreatAR Services, but excluding
          any and all KreatAR Content.
        </Typography>
        <Typography paragraph>
          2. TERM/FEES: This Agreement shall remain in full force and effect for
          as long as you use any of the KreatAR Sites or KreatAR Services,
          whether as a Visitor, Registered Member, or Paid Subscriber ("Term").
          You may terminate your free account or subscription at any time, for
          any reason. KreatAR may terminate your free account or subscription at
          any time, for any reason, effective immediately upon sending notice to
          you at the email address you provided during the account set-up
          process, or such other email address as you may subsequently provide
          to KreatAR. If KreatAR terminates your free account or subscription in
          the Service due to a breach of this Agreement, you shall not be
          entitled to the refund of any unused portion of subscription fees (if
          any). Even after the free account and/or subscription is terminated,
          this Agreement will remain in full force and effect, provided,
          however, that the User Materials submitted by you may no longer be
          accessible on the KreatAR Sites once your account has been cancelled.
          Notwithstanding the foregoing, you acknowledge that once your User
          Materials are integrated into a KreatAR Sites production (e.g.,
          photos, audio files, or videos integrated within an animation,
          promotion, video, or any KreatAR Sites production, regardless of media
          format), all of the licenses for such materials, as granted in
          Sections 8 and 9 of this Agreement, shall extend in perpetuity,
          meaning that KreatAR will have the perpetual right throughout the
          world to exploit such KreatAR Sites production embodying your User
          Materials in any and all media now known or hereafter devised. By
          using the KreatAR Services and/or by becoming a Paid Subscriber, you
          acknowledge that KreatAR reserves the right to charge a payment for
          the KreatAR Services and the right to terminate your account or
          subscription should you breach this Agreement or fail to pay for the
          Service, as required by this Agreement. Please keep in mind that
          KreatAR's subscription fees at registration (if any) may change from
          time to time.
        </Typography>
        <Typography paragraph>
          3. ELIGIBILITY; LIMITED USER LICENSE FOR KreatAR CONTENT: Use of the
          KreatAR Sites or KreatAR Services is void where prohibited by law. By
          signing up to become a Registered Member or Paid Subscriber on any of
          the KreatAR Sites or KreatAR Services, you represent and warrant that:
          (i) all registration information submitted directly by you or via
          third-party sign-on service (e.g., Facebook Connect) is truthful and
          accurate and you agree to maintain the accuracy of such information;
          (ii) you are at least 13 years of age; and (iii) your use of the
          KreatAR Sites and KreatAR Services does not violate any applicable law
          or regulation. Your account information and data may be deleted
          without warning if it is discovered that you have misrepresented your
          age or any other account and/or membership data. We may, in our sole
          discretion, refuse to offer the KreatAR Services to any user or entity
          that does not meet our eligibility criteria and we retain the right to
          change such eligibility criteria at any time. Subject to your strict
          compliance with this Agreement and except as otherwise expressly
          permitted by this Agreement or by KreatAR, KreatAR grants you a
          limited, personal, non-exclusive, non-commercial, revocable,
          non-assignable, and non-transferable license to download, view, and/or
          play one copy of the KreatAR Content (excluding source and object
          code) for your personal, non-commercial use only, PROVIDED that: (i)
          you maintain all copyright and other proprietary notices contained in
          the KreatAR Content or any copy you may make of the KreatAR Content;
          (ii) you do not use the KreatAR Content in a manner that suggests an
          association with KreatAR or any of its Licensors/Suppliers or any of
          their products, services or brands; (iii) you do not modify the
          KreatAR Content; (iv) you do not allow or aid or abet any third party
          (whether or not for your benefit) (a) to copy or adapt the object code
          of any of the KreatAR Sites or KreatAR Services or (b) reverse
          engineer, decompile, reverse assemble, modify, or attempt to discover
          any source code associated with any of the KreatAR Sites or KreatAR
          Services or other products or processes accessible through the KreatAR
          Sites or KreatAR Services; and (v) you do not insert any code or
          product to manipulate the KreatAR Content in any way that affects any
          user's experience. You also agree that you will not: (a) use any
          robot, spider, rover, scraper, or any other data mining technology or
          automatic or manual process to monitor, cache, frame, mask, extract
          data from, copy, or distribute the KreatAR Content (except as may be a
          result of standard search engine or internet browser usage), nor will
          you (b) modify, frame, reproduce, archive, sell, lease, rent,
          exchange, create derivative works from, publish by hard copy or
          electronic means, publicly perform, display, disseminate, distribute,
          broadcast, retransmit, circulate to any third party or on any
          third-party web site, or otherwise use the KreatAR Content in any way
          for any public or commercial purpose except as expressly permitted by
          this Agreement or by KreatAR.
        </Typography>
        <Typography paragraph>
          4. USER CONDUCT: KreatAR is glad to provide services to you, but you
          need to do your part as well. All of the KreatAR Sites, KreatAR
          Content, and KreatAR Services are solely for your personal use, and
          corporate or commercial use is expressly prohibited. You are solely
          responsible for all User Materials posted or distributed by you or
          through your account with KreatAR, including any email messages, and
          for all your interactions with other users. Your commitment to this
          agreement is extremely important. We want all of our users to feel
          safe while using our services and enjoy our great content without
          worrying. In order to experience all of KreatAR's amazing services we
          ask that you act responsibly in a manner demonstrating the exercise of
          good judgment. For example and without limitation, you agree not to:
          (i) violate any applicable law or regulation; (ii) submit any
          materials that conflict with any of your representations and
          warranties set forth in Section 15 of this Agreement; (iii) infringe
          the rights of any third party, including without limitation,
          intellectual property, privacy, publicity, and/or contractual rights;
          (iv) use any content or information available on the KreatAR Sites or
          through the KreatAR Services for any unauthorized purpose; (v) mislead
          users as to the features, functionality, origin, or capabilities of
          the KreatAR Sites or KreatAR Services; (vi) interfere with or damage
          any of the KreatAR Sites or KreatAR Services, including, without
          limitation, through the use of viruses, cancel bots, Trojan horses,
          harmful code, flood pings, denial of service attacks, packet or IP
          spoofing, forged routing of electronic mail address information, or
          similar methods or technology; (vii) use any of the KreatAR Sites or
          KreatAR Services to transmit, distribute, post, or submit any
          information concerning any other person or entity, including without
          limitation, photographs of others, personal contact information, or
          account numbers, or any defamatory materials of any kind, except where
          you have obtained express permission from such other person or entity
          in connection with any of the foregoing; (viii) use any of the KreatAR
          Sites or KreatAR Services in connection with the distribution of
          unsolicited commercial e-mail ("Spam") or advertisements; (ix) "stalk"
          or harass any other user of the KreatAR Sites or KreatAR Services; (x)
          harass, threaten, or verbally abuse the Performer during a Live Video
          Call; (xi) collect or store any information about any other user other
          than in the course of the permitted use of the KreatAR Sites and
          KreatAR Services; (xii) use any of the KreatAR Sites or KreatAR
          Services for any commercial purpose whatsoever (including, without
          limitation, to research, advertise, market, promote, sell, or
          otherwise exploit any product or service); (xiii) sell or otherwise
          transfer any User information (e.g., user profiles) or other user's
          User Materials); or (xiv) assist any third party in doing any of the
          foregoing. KreatAR reserves the right, in its sole discretion and
          without prior notice to you, to limit the amount of KreatAR Content
          and Collaborative Content you may send and the number of recipients to
          whom you may send such KreatAR Content or Collaborative Content
          through the KreatAR Sites. KreatAR may terminate your account and/or
          subscription if you violate the foregoing, and we may prohibit you
          from creating new accounts with KreatAR. If KreatAR determines, in its
          sole discretion, that you are a repeat infringer of third-party
          intellectual property rights, KreatAR will terminate your account
          and/or subscription, as the case may be, and prohibit you from
          creating new accounts with KreatAR. You are solely responsible for any
          interactions with other users of the KreatAR Services. KreatAR will
          not be responsible for any damage or harm resulting from your
          interactions with other users of the KreatAR Services. KreatAR
          reserves the right, but shall have no obligation, to monitor
          interactions between you and other users of the KreatAR Services and
          take any action in good faith to restrict access to or the
          availability of any material that KreatAR or another user of the
          KreatAR Services may consider to be obscene, lewd, defamatory,
          lascivious, filthy, excessively violent, harassing, or otherwise
          objectionable. Your KreatAR account may NOT include any photographs
          that contain nudity, violence, or offensive subject matter, as
          determined in KreatAR's sole discretion. Information provided by other
          KreatAR users may contain inaccurate, inappropriate and/or offensive
          material, and KreatAR assumes no responsibility or liability for this
          material.
        </Typography>
        <Typography paragraph>
          5. PRIVACY: Your privacy is extremely important to us. The KreatAR
          Sites and KreatAR Services allow, among other things, users who are 13
          or older to post personal content (e.g., photographs and information
          about themselves and others for whom they have the authority or
          permission to post photos and information) for public review and
          comment, both in public forums and with private messaging.
          Accordingly, by submitting personal content on any of the KreatAR
          Sites or through any of the KreatAR Services, you hereby waive any and
          all privacy expectations (including the privacy expectations of any
          other individual who appears in the submitted content) with respect to
          KreatAR's use of such content. If you do not wish to have personal
          content viewable by others, you should not use the KreatAR Sites and
          KreatAR Services. Children should only access and use such sites and
          services with the participation and supervision of their parents. Only
          parents are allowed to create accounts and profiles and post personal
          content on such sites and services, and KreatAR does not knowingly
          collect personal information directly from children on such sites and
          services. Please click hereto view the KreatAR Privacy Policy for
          details concerning our data practices.
        </Typography>
        <Typography paragraph>
          6. OWNERSHIP: You retain all right, title and interest in and to the
          User Materials provided by you hereunder (including, without
          limitation, the copyrights therein and thereto), subject to the
          non-exclusive rights granted to KreatAR under this Agreement. You are
          free to grant similar rights to others during and after the Term of
          this Agreement. For the avoidance of doubt, when you submit User
          Materials on any of the KreatAR Sites or through any of the KreatAR
          Services, you acknowledge that third parties may use your User
          Materials. You acknowledge and agree that, as between KreatAR and you,
          KreatAR and/or its Licensors/Suppliers (as the case may be) are, and
          shall at all times remain, the sole and exclusive owner(s) of all
          right, title, and interest (including, without limitation, copyright)
          in and to the KreatAR Sites, KreatAR Services, all Trademarks, and all
          KreatAR Content, and that your right to use the KreatAR Sites, KreatAR
          Services, Trademarks, and KreatAR Content, as set forth herein, shall
          be for personal, non-commercial use only and shall not in any way
          transfer or convey any ownership rights or other proprietary interests
          therein to you.
        </Typography>
        <Typography paragraph>
          7. LICENSE FOR USER MATERIALS: Unless expressly otherwise stated in
          this Agreement or otherwise agreed between you and KreatAR in a
          writing signed by both parties, you hereby grant to KreatAR a
          worldwide, royalty-free, sublicensable, transferable, assignable,
          non-exclusive license to do the following things during the Term (and
          in perpetuity with respect to any KreatAR production as set forth in
          Section 3 of this Agreement): (i) to prepare and encode your User
          Materials, or any portion thereof for electronic, digital and/or other
          transmission, manipulation and exhibition in any format and by any
          means now known or hereafter devised; (ii) to display, copy,
          reproduce, create derivative works of, exhibit, publicly perform,
          broadcast, rebroadcast, transmit, retransmit, promote, distribute
          through any means (including electronic, analog and digital), and
          publish and/or otherwise exploit, in digital or physical form, any or
          all of your User Materials, including any portion thereof, and to
          include any such materials in compilations or other works, by any and
          all means in all media now known or hereinafter created, anywhere in
          the world, and for any purpose (for avoidance of doubt, the rights
          granted to KreatAR hereunder include the rights to make your User
          Materials available on the KreatAR Sites, third-party websites and
          electronic devices); (iii) to modify, adapt, change, or otherwise
          alter and create derivative works of the User Materials and use your
          User Materials as described in Section 8(ii); and (iv) to license
          and/or sublicense to any third party any of the foregoing rights in
          your User Materials, or any part or element thereof, subject to the
          terms and conditions of this Agreement. You hereby agree not to assert
          any right, title, or interest in any and all Trademarks and KreatAR
          Content with which your User Materials may be combined or into which
          all or any portion of your User Materials may be incorporated. You
          acknowledge and agree that all right, title, and interest (including,
          without limitation, copyright, trademark and other intellectual
          property rights) in and to any and all Trademarks and KreatAR Content
          shall remain the sole and exclusive property of KreatAR and/or
          Licensors/Suppliers (as the case may be). For the avoidance of doubt,
          with respect to any and all Collaborative Content, you acknowledge and
          agree that your rights therein and thereto shall be expressly limited
          to your User Materials contained in such Collaborative Content and
          shall in no event extend to any KreatAR Content or any Trademarks
          contained or embodied therein.
        </Typography>
        <Typography paragraph>
          8. LICENSE FOR USE OF NAME AND LIKENESS: Unless expressly otherwise
          stated in this Agreement or otherwise agreed between you and KreatAR
          in a writing signed by both parties, you hereby grant to KreatAR a
          worldwide, royalty-free, sublicensable, transferable, assignable,
          non-exclusive license to use (a) your name(s), photograph(s),
          likeness(es), voice(s), performance(s), and biographical materials (to
          the extent contained or embodied in your User Materials) and (b) any
          other individual's name(s), photograph(s), likeness(es), voice(s),
          performance(s), and biographical materials, where such other
          individual appears in your User Materials, in connection with the
          distribution, exploitation, promotion, marketing and advertising of
          your User Materials, as described hereunder, during the Term (and in
          perpetuity with respect to any KreatAR production as set forth in
          Section 3 of this Agreement). You also agree not to assert any
          privacy, publicity, moral or similar rights held by you (and to the
          extent any other person(s) whose name(s), photograph(s), likeness(es),
          voice(s), performance(s), and/or biographical materials are embodied
          in your User Materials, you represent and warrant that you have
          obtained all necessary consents from such third parties consistent
          with the full scope of rights granted to KreatAR pursuant to this
          Agreement, and you agree that such persons shall not assert any
          intellectual property, privacy, publicity, contractual, moral, or
          similar rights, or make any claims that your User Materials are
          objectionable or otherwise defamatory) under the laws of the United
          States and any other country in connection with the exploitation of
          such materials as described hereunder.
        </Typography>
        <Typography paragraph>
          9. UNSOLICITED USER SUBMISSIONS. KreatAR does not solicit user
          submissions of content/product/service/business ideas, concepts,
          suggestions or proposals, including, without limitation, stories,
          scripts, fan fictions, characters, designs, drawings or artwork. We
          ask that you do not make unsolicited submissions to KreatAR. If you do
          make an unsolicited submission to KreatAR, however, you expressly
          acknowledge and agree that: (i) your submission is not being made in
          confidence or in trust, and no contractual, fiduciary or confidential
          relationship of any kind (whether express or implied) is created
          between you and KreatAR by reason of your submission; (ii) KreatAR and
          its affiliates, licensees, successors and assigns will be free (with
          no obligation) to use your submission for any purpose and in any
          format or medium, and KreatAR and its affiliates, licensees,
          successors and assigns will have no obligation or liability to you
          whatsoever, and you will have no right or claim to any compensation,
          payment, credit, attribution, notice, approval, or inspection of any
          kind whatsoever with respect to any such use; (iii) KreatAR or third
          parties may have independently developed, or may hereafter
          independently develop content or material that is similar to your
          submission, and any similarity between your submission and any KreatAR
          content or material or production is purely coincidental; (iv) your
          submission is not returnable and may be retained indefinitely by
          KreatAR and its affiliates, licensees, successors and assigns; (v) to
          the extent your submission includes any idea, concept, recommendation,
          suggestion or proposal for a prospective product or service name,
          mark, logo, slogan, tagline, domain name, or other source identifier,
          KreatAR and its affiliates, licensees, successors and assigns will be
          free (with no obligation) to adopt, use, register, and license to
          others such name, mark, logo, slogan, tagline, domain name, or other
          source identifier (including any variation thereof) for and in
          connection with their businesses throughout the world in perpetuity,
          and will have sole ownership of all statutory and common law trademark
          rights and all associated goodwill resulting from such adoption, use,
          registration and licensing; and (vi) you waive the right to assert
          against KreatAR and its affiliates, licensees, successors and assigns,
          and you release and forever discharge KreatAR and its affiliates,
          licensees, successors and assigns from, any and all claims and damages
          based upon or relating to your submission, including, without
          limitation, claims and damages for copyright infringement, trademark
          infringement, invasion of privacy, violation of the right of
          publicity, defamation, libel, false light, unfair competition, unjust
          enrichment, idea theft, idea misappropriation, breach of implied
          contract, breach of fiduciary duty, and/or breach of confidence or
          trust.
        </Typography>
        <Typography paragraph>
          10. THIRD PARTY CONTENT & SITES: The KreatAR Sites and KreatAR
          Services may contain Content of third parties, including Content
          provided by Licensors/Suppliers and users (collectively "Third Party
          Content"), as well as links to third party web sites ("Third Party
          Sites"). KreatAR does not control Third Party Content and Third Party
          Sites and makes no representations or warranties about them. You
          understand that by using the KreatAR Sites and KreatAR Services, you
          may be exposed to Third Party Content or Third Party Sites that are
          false, offensive, indecent or otherwise objectionable. Under no
          circumstances will KreatAR be liable in any way for any Third Party
          Content or Third Party Sites, including, without limitation, any
          errors or omissions in any Third Party Content or Third Party Sites or
          any loss or damage of any kind incurred as a result of the use of any
          Third Party Content or Third Party Sites. You agree to bear all risks
          associated with using or relying upon Third Party Content or Third
          Party Sites, including without limitation, profiles of other users.
          YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH ANY THIRD PARTY ON OR
          THROUGH ANY OF THE KreatAR SITES OR KreatAR SERVICES INCLUDING,
          WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF PRODUCTS AND SERVICES,
          AND ANY TERMS, CONDITIONS, WARRANTIES AND REPRESENTATIONS ASSOCIATED
          WITH ANY SUCH DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD PARTY.
          YOU AGREE TO REVIEW AND EVALUATE ALL POLICIES, RULES, TERMS AND
          REGULATIONS, INCLUDING THE PRIVACY POLICIES AND TERMS OF USE OF EACH
          AND ANY THIRD PARTY SITE THAT YOU VISIT, AND BEAR ALL RISKS ASSOCIATED
          WITH, THIRD PARTY CONTENT AND THIRD PARTY SITES.
        </Typography>
        <Typography paragraph>
          11. PROPRIETARY RIGHTS: You acknowledge and agree that all KreatAR
          Sites, KreatAR Services, Trademarks, and KreatAR Content are the
          property of KreatAR and/or Licensors/Suppliers (as the case may be)
          and are protected by rights of publicity, copyright, trademarks,
          service marks, patents, trade secrets, or other proprietary rights and
          laws. Except as expressly authorized by KreatAR and/or
          Licensors/Suppliers (as applicable), you may not sell, license, rent,
          modify, distribute, copy, reproduce, transmit, publicly display,
          publicly perform, publish, adapt, edit, or create derivative works
          from, any Trademark or KreatAR Content, or otherwise use any Trademark
          or KreatAR Content in any way. You also agree not to retrieve data or
          other content or any materials from the KreatAR Sites to create or
          compile, directly or indirectly, a collection, compilation, database,
          directory or the like, whether by manual methods, through the use of
          "bots" or otherwise. You further agree not to use any Trademark or
          KreatAR Content as metatags on other web sites. Moreover, you agree
          not to display any of the KreatAR Sites in a frame (or any of our
          content via in-line links) without KreatAR's express written
          permission.
        </Typography>
        <Typography paragraph>
          12. LINKING POLICY: Because we want you to share all the fun content
          that you make on our sites, you may link from your own website to one
          or more KreatAR Sites, PROVIDED, however that you: (i) must NOT frame
          or create a browser or border environment around any of the KreatAR
          Content on the linked KreatAR Site or otherwise mirror any part of the
          linked KreatAR Site; (ii) must NOT imply that KreatAR and/or any of
          its Licensors/Suppliers or the linked KreatAR Site is endorsing or
          sponsoring you, your site or any of your products or services, unless
          expressly authorized in writing by KreatAR and/or its
          Licensors/Suppliers; (iii) must NOT present false information about,
          or disparage, tarnish, or otherwise, in KreatAR's sole opinion, harm
          KreatAR and/or any of its Licensors/Suppliers in any way; (iv) must
          NOT use any KreatAR Trademarks without the prior written permission of
          KreatAR, or use any Licensors/Suppliers Trademarks without the prior
          written permission of their respective owners; and (v) must NOT
          contain Content that could be construed as distasteful, offensive or
          controversial or otherwise objectionable (in our sole opinion). By
          linking to any of the KreatAR Sites, you agree to be bound by and
          comply with all of the above linking restrictions and requirements.
          Notwithstanding anything to the contrary contained in this Agreement,
          KreatAR reserves the right to prohibit and deny linking to any of the
          KreatAR Sites for any reason in its sole and absolute discretion, even
          if the linking complies with all of the restrictions and requirements
          described above.
        </Typography>
        <Typography paragraph>
          13. USERNAME AND PASSWORD: Any eligible user who creates a KreatAR
          account will select a username and password when completing the
          registration process or create an account using third-party single
          sign-on services (e.g. Facebook Connect). You are solely and fully
          responsible for maintaining the confidentiality of your username and
          password, or third-party credentials, for accessing KreatAR and will
          be solely and fully responsible for all activities that occur under
          that account to access the KreatAR Sites. You agree to (a) immediately
          notify KreatAR of any unauthorized use of your username and password
          or any other breach of security and (b) log off from your account at
          the end of each session. KreatAR cannot and will not be liable for any
          loss or damage arising from your failure to comply with this Section
          14.
        </Typography>

        <Typography paragraph>
          14. REPRESENTATIONS AND WARRANTIES: You represent and warrant that: 1.
          you have the full right and power to enter into and perform this
          Agreement and to grant KreatAR and Licensors/Suppliers all rights to
          use your User Materials (including individuals' names, likenesses,
          voices, and other elements of identity embodied therein) as
          contemplated in this Agreement, including, without limitation, the
          license grants in Sections 8 and 9 of this Agreement; 2. you
          exclusively own and/or control all right, title and interest
          (including, without limitation, copyright) in and to your User
          Materials, and have secured all necessary third-party consents,
          rights, licenses and permissions, if any, required in order for you to
          enter into and perform this Agreement and to grant KreatAR and
          Licensors/Suppliers all rights to use your User Materials (including
          individuals names, likenesses, voices, and other elements of identity
          embodied therein) as contemplated in this Agreement (including,
          without limitation, consents and permissions from owners of any
          elements that are used or otherwise incorporated into your User
          Materials); 3. your User Materials (and KreatAR's use thereof as
          contemplated under this Agreement) do not and will not infringe on any
          rights of any third party, including any trademark, copyright, patent,
          trade secret, right of privacy or publicity, or moral rights of any
          third party; 4. all information that you have provided or will provide
          to KreatAR is true and complete; 5. your User Materials do not and
          will not violate any law, statute, ordinance or regulation; 6. your
          User Materials do not and will not: (i) be defamatory, libelous,
          slanderous, or threatening; (ii) contain sexually explicit content
          that is pornographic, obscene, harmful to minors, violations of child
          pornography or child sexual exploitation laws; (iii) denigrate any
          ethnic, racial, sexual or religious group by stereotypical depiction
          or otherwise; (iv) exploit images or the likeness of any individual
          other than yourself (except where your have obtained express
          permission from such other individual(s) for such exploitation); (v)
          encourage or otherwise depict glamorized drug use; (vi) make use of
          offensive language or images; (vii) promote physical harm of any kind
          against any individual or group or characterize violence as
          acceptable, glamorous or desirable; (viii) contain your or any other
          individual's personal contact information; (ix) promote an illegal or
          unauthorized copy of another person's copyrighted work, such as
          providing pirated computer programs or links to them, providing
          information to circumvent manufacture-installed copy-protect devices,
          or providing pirated music or links to pirated music files; (x)
          provide instructional information about illegal activities such as
          making or buying illegal weapons, violating someone's privacy, or
          providing or creating computer viruses; (xi) solicit passwords or
          personal identifying information for commercial or unlawful purposes
          from other users; and/or (xii) engage in any commercial activities
          whatsoever and/or sales without KreatAR's prior written consent; and
          7. your User Materials do not and will not contain any viruses or
          other programming routines that may detrimentally interfere with
          computer systems or data, whether those of KreatAR or any third party.
        </Typography>

        <Typography paragraph>
          15. USER DISPUTES: It is important to keep in mind that you are solely
          responsible for your interactions with other users. KreatAR reserves
          the right, but has no obligation, to monitor disputes between you and
          other users.
        </Typography>

        <Typography paragraph>
          16. DOWNLOADABLE ITEMS; COLLABORATIVE CONTENT: To the extent that
          KreatAR makes any applications, software or functionalities available
          for download or use from or through any of the KreatAR Sites or
          KreatAR Services (the "Downloadable Items"), such Downloadable Items
          are the copyrighted work (as between you and KreatAR) of KreatAR
          and/or its Licensors/Suppliers. Your use of the Downloadable Items may
          be governed by additional terms and conditions, which may be included
          with the Downloadable Items. Please carefully read any such additional
          terms and conditions to determine the full extent of conditions
          governing the use of such Downloadable Items. If you install a
          Downloadable Item that is a software application, you consent to the
          download of such software to your computer or device and accept this
          Agreement and any additional terms and conditions related to such
          software application. With respect to any User Materials that you
          download from or through any of the KreatAR Sites or KreatAR Services,
          you are responsible for all licensing, reporting and payment
          obligations of any kind to third parties in connection with such
          downloaded User Materials (including, without limitation, any such
          obligations that may arise from use by KreatAR or its
          Licensors/Suppliers of such User Materials as authorized in this
          Agreement). You also agree that any Downloadable Items you obtain from
          any of the KreatAR Sites or KreatAR Services (whether for free or for
          a fee) are only provided to you for your personal, non-commercial use
          and are not meant for you to further distribute. Your use of any
          Collaborative Content is subject to the following additional terms and
          conditions: (i) the permission for you to use KreatAR Content to
          generate Collaborative Content or otherwise in association with your
          User Materials is expressly limited to KreatAR Content specifically
          made available by KreatAR for that purpose, and may be revoked by
          KreatAR at any time without notice or liability to you; (ii) your use
          of Collaborative Content is subject to this Agreement and any
          additional terms and conditions as KreatAR may from time to time
          prescribe; (iii) you may NOT make any commercial use of any
          Collaborative Content or of any KreatAR Content embodied in any
          Collaborative Content, in whole or in part, or sell, lease,
          hypothecate, transfer, license, distribute, reproduce, encumber, or
          otherwise exploit same, in whole or in part, EXCEPT that you may use
          the KreatAR Sites and KreatAR Services to generate and engage in Viral
          Distribution (as defined below) of Collaborative Content, but in each
          case only to the extent expressly permitted by this Agreement or
          otherwise by KreatAR; as used herein, "Viral Distribution" means, for
          non-commercial purposes only: (a) sending Collaborative Content to
          friends, acquaintances at no charge by e-mail or other forms of
          digital delivery; (b) reproducing copies of Collaborative Content for
          personal use; and (c) posting and displaying links to Collaborative
          Content on a personal web site or on a third party web site that
          permits posting of such links at the direction of users subject to its
          terms and conditions, provided that such third party web site does not
          charge for access to the Collaborative Content or associate products,
          services or advertising with the Collaborative Content; (iv) you must
          always include and keep intact, and not remove or alter, any and all
          of KreatAR's and its Licensors/Suppliers' trademarks, copyrights and
          other proprietary rights notices, legends, watermarks, and other
          markings contained or embedded in or otherwise accompanying
          Collaborative Content (or any KreatAR Content portion thereof), and
          you agree to comply with all usage and viral distribution guidelines
          that may be prescribed by KreatAR from time to time; and (v) YOU AGREE
          NOT TO ENGAGE IN SPAMMING OR OTHER UNLAWFUL OR CONTROVERSIAL BEHAVIOR
          IN CONNECTION THEREWITH.
        </Typography>

        <Typography paragraph>
          17. INDEMNITY: You agree to defend, indemnify, reimburse, and hold
          KreatAR and its parent, subsidiary and affiliated entities, its
          Licensors/Suppliers, and its and their respective members, managers,
          officers, directors, representatives, employees, agents, successors,
          designees, licensees, sublicensees, and assigns harmless from and
          against any and all liability, loss, damages, judgments, costs, and
          expenses (including reasonable attorney's fees, costs and expenses and
          court costs) arising out of or related to: (i) your use of the KreatAR
          Sites and KreatAR Services; (ii) KreatAR's use of your User Materials;
          (iii) any breach or alleged breach of your representations and
          warranties and/or any breach, alleged breach, or violation of the
          notices, terms, and conditions hereof; and (iv) your violation or
          alleged or threatened violation of any laws, rules or regulations, or
          any rights of a third party, including, without limitation, any
          trademark, copyright, patent, trade secret, defamation, right of
          privacy or publicity, or moral rights of any third party, arising from
          the submission and/or use of your User Materials as contemplated
          hereunder.
        </Typography>

        <Typography paragraph>
          18. KreatAR'S PERFORMANCE: KreatAR strives to provide you with the
          best possible experience, but we cannot guarantee that there will not
          be any errors or interruptions on our end. You acknowledge and agree
          that the operation of the KreatAR Sites may from time to time
          encounter technical or other problems and may not necessarily continue
          uninterrupted or without technical or other errors and KreatAR shall
          not be responsible to you or others for any such interruptions, errors
          or problems or an outright discontinuance of the KreatAR Services.
          There are no assurances whatsoever that any of your User Materials or
          any part or element thereof shall actually be utilized on any of the
          KreatAR Sites or if so utilized continue to be available for any
          particular time. KreatAR has the right, in KreatAR's sole and absolute
          discretion, to remove from the KreatAR Sites at any time your User
          Materials or any part thereof and/or to revoke any sublicense granted
          by KreatAR to any affiliate or unaffiliated third party.
          Notwithstanding the foregoing, KreatAR does not control the content of
          any User Materials and does not have any obligation to monitor such
          content for any purpose. You acknowledge that you are solely
          responsible for all content submitted to the KreatAR Sites. The
          KreatAR Sites may be discontinued at any time, with or without reason
          and without any liability to you or to any third party for any
          modification or discontinuance of the KreatAR Services.
        </Typography>

        <Typography paragraph>
          19. NO WARRANTY: ALL OF THE KreatAR SITES, KreatAR SERVICES, AND
          KreatAR CONTENT ARE PROVIDED ON AN "AS IS", "AS AVAILABLE", AND "WITH
          ALL FAULTS" BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          KreatAR AND ITS LICENSORS/SUPPLIERS DISCLAIM ALL WARRANTIES, EITHER
          EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO: UNINTERRUPTED OR
          CONTINUOUS AVAILABILITY OF THE KreatAR SITES AND KreatAR SERVICES, AND
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, AND NONINFRINGEMENT WITH RESPECT TO THE KreatAR SITES AND
          KreatAR SERVICES. IN ADDITION, ALTHOUGH KreatAR INTENDS TO TAKE
          REASONABLE STEPS TO PREVENT THE INTRODUCTION OF VIRUSES OR OTHER
          DESTRUCTIVE MATERIALS TO THE KreatAR SITES, AND WHETHER OR NOT KreatAR
          IMPLEMENTS THE AFOREMENTIONED REASONABLE PROTECTIONS, KreatAR DOES NOT
          WARRANT THAT THE KreatAR SITES, ANY PART THEREOF, OR ANY INFORMATION
          OR OTHER MATERIAL ACCESSIBLE THROUGH THE KreatAR SITES IS FREE OF
          VIRUSES, WORMS, TROJAN HORSES OR OTHER HARMFUL COMPONENTS. SOME
          JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER
          WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH
          JURISDICTION'S LAW IS APPLICABLE TO THESE TERMS. BY ACCESSING OR USING
          ANY OF THE KreatAR SITES OR KreatAR SERVICES, YOU REPRESENT AND
          WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE
          YOU ACCESS OR USE SUCH KreatAR SITES OR KreatAR SERVICES.
        </Typography>

        <Typography paragraph>
          20. NO LIABILITY FOR THIRD PARTY USE: KreatAR DISCLAIMS ALL
          RESPONSIBILITY AND LIABILITY FOR ANY THIRD-PARTY USE OF THE USER
          MATERIALS MADE AVAILABLE ON THE KreatAR SITES BY YOU PURSUANT TO THE
          TERMS OF THIS AGREEMENT. YOU SHALL BE SOLELY RESPONSIBLE FOR SEEKING
          RELIEF FOR ANY UNAUTHORIZED USE OF YOUR USER MATERIALS BY A
          THIRD-PARTY, AND NOT FROM KreatAR OR ANY OF ITS LICENSORS/SUPPLIERS.
          THIS MEANS, AMONG OTHER THINGS, THAT IF ANOTHER PERSON OBTAINS YOUR
          USER MATERIALS FROM KreatAR (WHETHER OR NOT WITH KreatAR'S
          PERMISSION), AND USES THOSE MATERIALS IN A WAY NOT AUTHORIZED PURSUANT
          TO THE LICENSES GRANTED HEREUNDER, YOU WILL SEEK REDRESS FROM THAT
          OTHER PERSON AND NOT FROM KreatAR OR ANY OF ITS LICENSORS/SUPPLIERS,
          AND THAT YOU WILL NOT HOLD KreatAR OR ANY OF ITS LICENSORS/SUPPLIERS
          RESPONSIBLE OR LIABLE FOR SUCH UNAUTHORIZED USE.
        </Typography>

        <Typography paragraph>
          21. EXCLUSION OF DAMAGES: TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, IN NO EVENT (INCLUDING NEGLIGENCE) SHALL KreatAR, ITS
          OFFICERS, DIRECTORS, MEMBERS, PARENTS, AFFILIATES, SUBSIDIARIES,
          LICENSEES, ASSIGNS, SUCCESSORS, AGENTS, REPRESENTATIVES, EMPLOYEES, OR
          LICENSORS/SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING,
          WITHOUT LIMITATION, DAMAGES ARISING OUT OF AN ACTION UNDER CONTRACT,
          NEGLIGENCE, OR ANY OTHER THEORY AND DAMAGES FOR LOSS OF BUSINESS
          PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER
          PECUNIARY LOSS, INCIDENTIAL DAMAGES, CONSEQUENTIAL DAMAGES, PUNITIVE
          DAMAGES, EXEMPLARY DAMAGES), WHETHER OR NOT KreatAR HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGE, ARISING OUT OF OR IN CONNECTION
          WITH THIS AGREEMENT OR THE USE OR PERFORMANCE OF THE KreatAR SITES
          AND/OR THE KreatAR SERVICES. IN NO EVENT SHALL KreatAR'S TOTAL
          LIABILITY TO YOU UNDER THIS AGREEMENT FROM ALL CAUSES OF ACTION AND
          UNDER ALL THEORIES OF LIABILITY EXCEED \$200. YOU EXPRESSLY WAIVE ANY
          AND ALL RIGHT TO SEEK OR OBTAIN EQUITABLE OR INJUNCTIVE RELIEF
          RELATING IN ANY WAY TO THIS AGREEMENT, THE SERVICES AND/OR ANY USER
          MATERIALS (INCLUDING, WITHOUT LIMITATION, WITH RESPECT TO ANY USE OF
          YOUR USER MATERIALS BY KreatAR). THE PARTIES ACKNOWLEDGE AND AGREE
          THAT KreatAR HAS ENTERED INTO THIS AGREEMENT IN RELIANCE ON THE
          LIMITATIONS OF LIABILITY SPECIFIED HEREIN, WHICH ALLOCATE THE RISK
          BETWEEN YOU AND KreatAR, AND FORM THE BASIS OF THE BARGAIN BETWEEN THE
          PARTIES.
        </Typography>

        <Typography paragraph>
          22. EXPORT CONTROLS: Software from the KreatAR Sites (the "Software")
          is further subject to United States export controls. No Software may
          be downloaded from the KreatAR Sites or otherwise exported or
          re-exported (a) into (or to a national or resident of) any Country to
          which the U.S. has embargoed goods or (b) to anyone on the U.S.
          Treasury Department's list of Specially Designated Nationals or the
          U.S. Commerce Department's Table of Deny Orders. By downloading or
          using the Software, you represent and warrant that you are not located
          in, under the control of, or a national or resident of any such
          country or on any such list.
        </Typography>

        <Typography paragraph>
          23. GOVERNING LAW, VENUE, AND JURISDICTION: You agree that the laws of
          the State of New York, without regard to principles of conflict of
          laws, will govern your use and purchase of products and services and
          these Terms of Service, and any dispute of any sort that might arise
          between you and KreatAR or any of our affiliates. With respect to any
          disputes or claims not subject to arbitration (as set forth below),
          you agree not to commence or prosecute any action in connection
          therewith other than in the state and federal courts located in the
          Southern District of New York, New York, and BOTH YOU AND KreatAR
          IRREVOCABLY consent to, and waive all defenses of lack of personal
          jurisdiction and forum non conveniens with respect to, venue and
          jurisdiction in the state and federal courts located in the Southern
          District of New York, New York.
        </Typography>

        <Typography paragraph>
          24. ARBITRATION YOU AND KreatAR AGREE THAT THE SOLE AND EXCLUSIVE
          FORUM AND REMEDY FOR ANY AND ALL DISPUTES AND CLAIMS RELATING IN ANY
          WAY TO OR ARISING OUT OF THESE TERMS OF SERVICE OR THE SALE OF ANY
          PRODUCT OR SERVICE BY KreatAR SHALL BE FINAL AND BINDING ARBITRATION,
          except that, to the extent that either of us has in any manner
          infringed upon or violated or threatened to infringe upon or violate
          the other party's patent, copyright, trademark or trade secret rights,
          such other party may seek injunctive or other appropriate relief.
          Arbitration under this Agreement shall be conducted by the American
          Arbitration Association (the "AAA") under its Commercial Arbitration
          Rules and, in the case of consumer disputes, the AAA's Supplementary
          Procedures for Consumer Related Disputes (the "AAA Consumer Rules")
          (collectively the "AAA Rules"). The location of the arbitration and
          the allocation of costs and fees for such arbitration shall be
          determined in accordance with such AAA Rules and shall be subject to
          the limitations provided for in the AAA Consumer Rules (for consumer
          disputes). The arbitrator's award shall be binding and may be entered
          as a judgment in any court of competent jurisdiction. To the fullest
          extent permitted by applicable law, NO ARBITRATION OR CLAIM UNDER
          THESE TERMS OF SALE SHALL BE JOINED TO ANY OTHER ARBITRATION OR CLAIM,
          INCLUDING ANY ARBITRATION OR CLAIM INVOLVING ANY OTHER CURRENT OR
          FORMER USER OF THE SITE OR ANY KreatAR PRODUCTS OR SERVICES, AND NO
          CLASS ARBITRATION PROCEEDINGS SHALL BE PERMITTED. In no event shall
          any claim, action, or proceeding by you related in any way to any
          products or services purchased by you on or through any of the KreatAR
          Sites be instituted more than one (1) year after the cause of action
          arose.
        </Typography>

        <Typography paragraph>
          25. ASSIGNMENT: KreatAR shall have the right to assign this Agreement
          in whole or in part to any person or business entity. You may not
          assign your rights or delegate your obligations under this Agreement
          without the prior written consent of KreatAR.
        </Typography>

        <Typography paragraph>
          26. NOTICES: All copyright infringement notifications to KreatAR in
          regards to any Content (including User Materials) on the KreatAR Sites
          must be made in accordance with the section entitled "NOTICE AND
          PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT" contained in
          KreatAR's Legal Notices (click here).
        </Typography>

        <Typography paragraph>
          All other notices, requests and other communications
          ("Communications") under this Agreement must be in writing and sent to
          The Glimpse Group Inc, 800 Third Avenue Suite 1701, New York NY 10022
          ATTN: Maydan Rothblum. Communications shall be deemed received: (i)
          for Communications sent by registered or certified mail, postage
          prepaid, return receipt requested or by Federal Express or other
          reputable overnight courier service, on the date of receipt as
          indicated on the return receipt; (ii) for Communications sent by
          personal delivery, on the date of personal delivery; No other form of
          notice shall be accepted under this Agreement.
        </Typography>

        <Typography paragraph>
          27. ENTIRE AGREEMENT: This Agreement, together with the KreatAR Terms
          of Sale, the KreatAR Privacy Policy, and the Legal Notices, sets forth
          the entire understanding and agreement of you and KreatAR as to the
          subject matter hereof and supersedes all prior proposals, discussions
          or agreements (oral and written) with respect to such subject matter.
          If any provision of this Agreement shall be unlawful, void, or for any
          reason unenforceable, then that provision shall be deemed severable
          from this Agreement and shall not affect the validity and
          enforceability of any remaining provisions. KreatAR's failure to act
          with respect to a breach by you or others does not waive KreatAR's
          right to act with respect to antecedent, subsequent or similar
          breaches.
        </Typography>

        <Typography paragraph>
          28. MODIFICATION: KreatAR reserves the right to change the terms of
          this Agreement from time to time in its sole discretion. In the event
          of such changes, KreatAR will post the changes on the KreatAR Sites
          and/or notify you via email. Also, KreatAR may ask you to review and
          consent to the changes at the time of your next account login or at
          the time of your next visit to the Site. By continuing to use any of
          the KreatAR Sites or KreatAR Services after such notice and consent,
          you agree to be bound by the changes to this Agreement and the new
          terms of the Agreement shall govern all prior and future submissions
          of your User Materials.
        </Typography>

        <Typography variant="h6" paragraph>
          Terms of Sale
        </Typography>
        <Typography paragraph>
          The following Terms of Sale apply to your use of all websites, mobile
          applications, and other products and services owned, controlled,
          operated or hosted (whether now or in the future) by KreatAR, LLC,
          Post Reality and/or its corporate affiliates and all related
          applications and services (collectively the "KreatAR Sites").
        </Typography>

        <Typography paragraph>Last Updated: November 8, 2017</Typography>

        <Typography paragraph>
          These Terms of Sale ("Terms of Sale") govern your purchase of goods
          and services (including, without limitation, your purchase of
          non-trial subscriptions, physical goods, gift subscriptions, and/or
          pay-per-download material) on or through any of the KreatAR Sites. By
          purchasing any products or services on any of the KreatAR Sites, you
          affirmatively acknowledge that you have read and understand these
          Terms of Sale and agree to be bound by them as in effect at the time
          of your purchase. By using any of the KreatAR Sites or any products or
          services offered thereon, you agree to be also bound by the KreatAR
          Terms of Service (click here), the KreatAR Privacy Policy (click
          here), and the Legal Notices (click here), all of which are
          incorporated herein by reference. All capitalized terms used herein
          that are not otherwise defined in these Terms of Sale shall be as
          defined in the KreatAR Terms of Service(click here).
        </Typography>

        <Typography paragraph>
          Please keep in mind that these Terms of Sale are subject to change by
          KreatAR, LLC from time to time. In the event of such changes, we will
          post the changes on the KreatAR Sites and/or notify you via email.
          Also, we may ask you to review and consent to the changes at the time
          of your next account login or at the time of your next purchase on or
          through any of the KreatAR Sites. Changes to these Terms of Sale shall
          apply to any purchases made after such notice and consent. Please
          review these Terms of Sale prior to each purchase so you will
          understand the terms applicable to such transaction. If you do not
          agree to these Terms of Sale, do not make any purchases on or through
          the KreatAR Sites.
        </Typography>

        <Typography paragraph>
          PLEASE READ THESE TERMS OF SALE CAREFULLY AS THEY CONTAIN IMPORTANT
          INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS.
          THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AND A DISPUTE
          RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
        </Typography>

        <Typography paragraph>
          1. SUBSCRIPTIONS You may be required to be a Paid Subscriber to access
          certain goods, services, and content offered on the KreatAR Sites
          (collectively "Subscription-based Features"). KreatAR, LLC may offer
          different subscription plans across different platforms, and some
          subscription plans may include special promotional pricing with
          differing conditions and limitations. With respect to any
          Subscription-based Features, you acknowledge and agree that, by making
          such features accessible to Paid Subscribers in general, KreatAR will
          have fully satisfied its obligation to deliver or otherwise provide
          such features to you (and/or to your intended authorized recipients),
          regardless of your or your recipients' failure or inability to use
          such features.
        </Typography>

        <Typography className="indent" paragraph>
          1.1 NON-TRIAL SUBSCRIPTIONS If you decide to register for a paid
          subscription, we will charge you the applicable subscription fee for
          the applicable subscription term using your selected payment method at
          the time of purchase. If KreatAR alters the subscription fee or makes
          other changes to your subscription, you will be notified in advance
          about the changes via email. It is important to keep in mind that if
          you signed up for a subscription during a promotional period, the
          promotional price may only be active for a certain period of time,
          based on our sole discretion.
        </Typography>
        <Typography className="indent" paragraph>
          1.2 NON-TRIAL SUBSCRIPTION RENEWALS As a Paid Subscriber, your
          non-trial subscription will renew either annually or monthly,
          depending on your applicable subscription plan. For example, if you
          start your annual subscription on March 31st, you will be billed on
          March 30th the following year. If you start your monthly subscription
          on March 31st, you will be billed on April 30th the following month.
          For some KreatAR products and services, your subscription will
          automatically be renewed at the end of the subscription term unless
          you turn off the auto-renew feature or cancel your subscription prior
          to the expiration date of your then-existing subscription. To cancel
          your subscription auto-renewal, please go to the "My Account" section
          of the KreatAR Site(s) where you have a membership account or to the
          applicable third-party interface if you subscribed to KreatAR through
          a third-party. PAYMENTS ARE NONREFUNDABLE AND THERE ARE NO REFUNDS, IN
          WHOLE OR IN PART, FOR PARTIALLY USED PERIODS. Following any
          cancellation, your access to the Subscription-based Features will
          continue only for the remainder of your applicable non-trial
          subscription term.
        </Typography>
        <Typography className="indent" paragraph>
          1.3 CANCELLATION You may cancel your subscription at any time.
          However, PAYMENTS ARE NONREFUNDABLE AND THERE ARE NO REFUNDS, IN WHOLE
          OR IN PART, FOR PARTIALLY USED PERIODS. Following any cancellation,
          your access to the Subscription-based Features will continue only for
          the remainder of your applicable non-trial subscription term. At
          KreatAR's sole discretion, we may provide a discount or other
          accommodation to some or all of our Paid Subscribers at any time and
          for any reason. Our offering of a discount or other accommodation in
          one instance does not entitle you to, or obligate us to provide, the
          same treatment in the future for similar instances. If you pay for a
          subscription through a third-party service (such as through the iTunes
          App Store, for example), you may need to access your account through
          that applicable third-party interface to cancel your subscription to
          or turn off auto-renew for the KreatAR Services. The billing
          information related to your non-trial subscription may also be
          accessible via your account with the applicable third party.
        </Typography>
        <Typography className="indent" paragraph>
          1.4 TRIAL PERIODS We know that sometimes you need a little time to try
          out the services before wanting to commit. In order to allow customers
          an opportunity to "sample" our products and services, occasionally we
          may offer trial periods during which you will have the ability to
          cancel your subscription within a stated period of time and receive a
          full refund. If you do not cancel during the trial period, your trial
          subscription will automatically be converted to a non-trial
          subscription upon expiration of the trial period. If you cancel after
          the trial period has ended, NO REFUND will be given, whether in whole
          or in part, and your access to the Subscription-based Features will
          continue only for the remainder of the non-trial subscription term. To
          cancel your trial subscription, please go to the "My Account" section
          of the KreatAR Sites where you have a membership account or to the
          applicable third-party interface if you subscribed to KreatAR through
          a third-party.
        </Typography>
        <Typography className="indent" paragraph>
          1.5 KreatAR PREMIUM CONTENT SERVICES KreatAR Sites may offer a premium
          subscription that allows our customers to experience all the fun
          content we have. It is important to keep in mind that a subscription
          may be required to access certain parts of KreatAR's service that
          allows you to create digital greeting cards and personalized media
          using certain content, including without limitation, audio,
          audiovisual, and/or still images (as the service may permit) from
          KreatAR's library (the "KreatAR Premium Content"), to personalize such
          KreatAR Premium Content (in certain instances), and to share such
          KreatAR Premium Content with others, all to the extent permitted by
          KreatAR (the "KreatAR Premium Content Services").
        </Typography>
        <Typography className="indent" paragraph>
          Unless otherwise noted, you will have access to your KreatAR Premium
          Content for a minimum of two (2) weeks upon creation, unless earlier
          removed by you or your recipient. All KreatAR Premium Content from
          KreatAR's library that is offered as part of the KreatAR Premium
          Content Services constitute part of KreatAR's proprietary content, and
          your use thereof is subject to your compliance with the KreatAR Terms
          of Service. In addition, any messages that you include with your
          KreatAR Premium Content must comply with all terms and conditions
          regarding User Materials and User Conduct as set forth in the KreatAR
          Terms of Service. For the avoidance of doubt, the KreatAR Premium
          Content Services are services (notwithstanding any use of the terms
          "purchase," "buy," "sell," "order," or the like on the KreatAR Sites
          or in these Terms of Sale), and neither you nor your recipient obtains
          or retains any rights or ownership interest of any kind in or to any
          KreatAR Premium Content, and neither you nor your recipient may
          reproduce, distribute, transfer, modify, create derivative works, or
          otherwise use such content in any manner other than as expressly
          authorized by KreatAR.
        </Typography>
        <Typography className="indent" paragraph>
          All use of the KreatAR Premium Content Services is for your personal,
          non-commercial use only.
        </Typography>
        <Typography className="indent" paragraph>
          1.6 KreatAR'S RIGHT TO TERMINATE We hope that it never has to reach
          this point, but if necessary, KreatAR, LLC reserves the right to
          terminate your account and/or subscription at any time for any breach
          or violation by you of the KreatAR Terms of Service. In the event of
          such termination, you will not be entitled to any refund of any unused
          portion of the subscription fee previously paid by you.
        </Typography>
        <Typography paragraph>
          2. A LA CARTE PURCHASES No subscription is necessary to purchase
          certain virtual goods, gift subscriptions, or material offered on a
          pay-per-download basis on the KreatAR Sites. For purchases of a la
          carte virtual goods, the purchase amount to your specified payment
          method when you click submit on the order or download page. For the
          avoidance of doubt, neither the term "physical goods" nor the term
          "pay-per-download material" will include online, streaming use of the
          KreatAR Premium Content described in Section 1.5 above (except for
          special orders and as otherwise noted by KreatAR).
        </Typography>
        <Typography className="indent" paragraph>
          2.1 GIFT SUBSCRIPTIONS You may purchase a gift subscription(s) that
          can be redeemed by your recipient within one (1) year of purchasing
          the account. A gift subscription has a term of one (1) year from the
          date it is issued. IF AND TO THE EXTENT A GIFT SUBSCRIPTION IS NOT
          USED WITHIN ONE (1) YEAR FROM THE DATE IT IS ISSUED, THE GIFT
          SUBSCRIPTION EXPIRES. Gift subscriptions can only be redeemed once and
          cannot be exchanged for cash.
        </Typography>
        <Typography className="indent" paragraph>
          2.2 PAY-PER-DOWNLOAD MATERIAL With respect to any a la carte virtual
          good or pay-per-download material that you purchase on or through the
          KreatAR Sites, you acknowledge and agree that, upon making such
          material available to you (or to your intended authorized recipients)
          for download, KreatAR will have fully satisfied its obligation to
          deliver or otherwise provide such material, regardless of your or your
          recipients' failure or inability to view, play, use, or store such
          material. All purchases of a la carte virtual goods and
          pay-per-download material on or through the KreatAR Sites are final,
          non-cancelable, and non-refundable, and no such material is returnable
          or exchangeable (except as expressly otherwise provided in these Terms
          of Sale or as otherwise determined by KreatAR in its sole discretion).
          For the avoidance of doubt, a la carte virtual goods and
          pay-per-download material is provided for your personal,
          non-commercial use only and remains the exclusive property of KreatAR.
        </Typography>

        <Typography paragraph>
          3. SUBSCRIPTION ENHANCEMENTS We are always improving our service and
          offering new promotions. We may offer enhancements related to your
          subscription and/or a la carte purchase options. For example, we may
          offer an ad-free subscription, a subscription that includes bonus
          content, or the ability to download digital video files to your
          desktop for an additional fee. Such subscription enhancements may be
          added for an additional NON-REFUNDABLE fee and may be cancelled at any
          time (provided that you will continue to have access to your purchased
          enhancements for the duration of your basic subscription). You may
          renew your basic subscription and choose not to renew your
          subscription enhancements, but you may not renew subscription
          enhancements without a basic subscription.
        </Typography>

        <Typography paragraph>
          4. GENERAL PROVISIONS 4.1 PURCHASE QUALIFICATIONS; ACCOUNT SECURITY To
          make any purchase on or through the KreatAR Sites (including, without
          limitation, purchase of subscriptions, a la carte virtual goods,
          physical goods, gift subscriptions, and/or pay-per-download material),
          you must be a Registered Member (as defined in the KreatAR Terms of
          Service) and comply with these Terms of Sale (including the KreatAR
          Terms of Service). You acknowledge that you are responsible for
          maintaining the security of, and restricting access to, your account
          and password, and you agree to accept responsibility for all purchases
          and other activities that occur under your account. KreatAR sells its
          products and services only to those who can legally make purchases
          with a credit card/debit card. If you are under 18 years of age (but
          not younger than 13), you may make purchases on or through the KreatAR
          Sites only with the consent and supervision of a parent or guardian.
          No one under 13 is allowed to make any purchase on any of the KreatAR
          Sites or KreatAR Services. KreatAR reserves the right to refuse or
          cancel any customer order or terminate any customer account with
          KreatAR, at any time in its sole discretion (except as prohibited by
          law).
        </Typography>

        <Typography className="indent" paragraph>
          4.2 PAYMENT METHOD AND TERMS We may accept credit cards and certain
          debit cards as forms of payment. By submitting an order on or through
          the KreatAR Sites, you authorize KreatAR, or its designated payment
          processor, to charge the purchase amount (and in the event of a
          subscription renewal, you authorize KreatAR, or its designated payment
          processor, to automatically charge the renewal amount) to the credit
          card/debit card account you specified. No purchase will become
          effective unless and until your payment has been accepted and
          processed. KreatAR also accepts payment through various third-party
          services (such as iTunes). In those cases, the terms of sale
          applicable to those third-party services will govern the processing of
          your payment.
        </Typography>

        <Typography className="indent" paragraph>
          KreatAR may have certain products or services that can be purchased
          through your mobile service (Premium SMS billing). You will find these
          charges on your mobile bill. Please keep in mind that you are
          responsible for any device connection charges you may incur when you
          use our services. If while using our application you use your cellular
          network (rather than Wi-Fi), you may be charged standard data rates.
          International data roaming charges may apply when using our
          application when traveling outside your home country. We recommend
          speaking about data roaming charges with your cellular carrier before
          using our application abroad. If you purchase a KreatAR product or
          service through texting messaging/SMS or use our sharing feature via
          mobile, then standard texting messaging rates/fees may apply. These
          purchases are non-refundable and final. KreatAR will not refund any
          purchase(s) made via an SMS charge in the event of technical
          incapability or non-functionality of the message.
        </Typography>

        <Typography className="indent" paragraph>
          KreatAR reserves the right to report, investigate, and prosecute to
          the fullest extent required or permitted under applicable law, any
          fraudulent, unauthorized, or otherwise unlawful use of any credit card
          or debit card by any person on or through the KreatAR Sites.
        </Typography>

        <Typography className="indent" paragraph>
          4.3 TAXES You are responsible for any and all applicable sales, use
          and other taxes, duties, and governmental fees and charges payable in
          connection with your purchase made on or through the KreatAR Sites. If
          you do not pay such taxes or fees on a transaction, you will be
          responsible for such taxes or fees in the event that they are later
          determined to be payable on such sale, and KreatAR reserves the right
          to collect or withhold such taxes or fees from you at any time.
        </Typography>

        <Typography className="indent" paragraph>
          4.4 PRODUCT AND SERVICE AVAILABILITY; ERRORS KreatAR may revise,
          discontinue, or modify products or services offered on or through the
          KreatAR Sites, at any time without prior notice to you, if a product
          or service offered by you is or becomes unavailable, KreatAR may
          decline or cancel your order (and issue you a refund if you have paid
          for the order), with no further liability or obligation to you. If a
          product or service offered by you is or becomes unavailable, KreatAR
          may, in its sole discretion, offer to substitute your order with
          another product or service of equal or greater value and will make
          such substitution only with your written or recorded consent. While we
          strive to eliminate errors on the KreatAR Sites, we do not warrant
          that all products, services, information, and content offered on the
          KreatAR Sites will be accurate, complete, reliable, current, or
          error-free. Unless expressly otherwise noted, all products, services,
          information, and content offered on the KreatAR Sites are provided "as
          is" without warranty of any kind. In the event of an error by KreatAR
          in processing or delivering your order, KreatAR may, at its election:
          (i) correct such error and revise your order accordingly if necessary
          (including charging the correct price and billing you for any
          undercharged amount or refunding to you any overcharged amount, as the
          case may be); or (ii) cancel your order affected by the error and
          issue a refund of the purchase amount actually paid by you for such
          order. By placing an order on or through the KreatAR Sites, you
          acknowledge and agree that, in the event of an error by KreatAR in
          processing or delivering your order, your sole remedy is to cancel
          your order and return the physical goods delivered (if any) for a
          refund of the purchase amount actually paid by you, all subject to
          KreatAR's cancellation, return and refund policies set forth in these
          Terms of Sale.
        </Typography>

        <Typography className="indent" paragraph>
          4.5 PRIVACY We care about the privacy of our customers. Please (click
          here) to view the KreatAR Privacy Policy.
        </Typography>

        <Typography className="indent" paragraph>
          4.6 DISCLAIMERS ANY WARRANTY ON PRODUCTS OR SERVICES PURCHASED THROUGH
          ANY OF THE KreatAR SITES ARE PROVIDED BY THE ORIGINAL MANUFACTURER
          ONLY AND NOT BY KreatAR. KreatAR ITSELF MAKES NO WARRANTIES OF ANY
          KIND, EXPRESS OR IMPLIED, WITH RESPECT TO ANY PRODUCTS OR SERVICES
          SOLD ON ANY OF THE KreatAR SITES. ALL PRODUCTS AND SERVICES ON THE
          KreatAR SITES ARE PROVIDED OR SOLD "AS-IS" AND KreatAR DISCLAIMS ANY
          AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF TITLE,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT. KreatAR CANNOT GUARANTEE AND DOES NOT PROMISE ANY
          SPECIFIC RESULTS FROM USE OF PRODUCTS OR SERVICES. KreatAR DOES NOT
          REPRESENT OR WARRANT THAT PRODUCTS, SERVICES, OR ANY PART THEREOF, ARE
          ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE OR THAT PRODUCTS
          OR SERVICES THAT ARE DOWNLOADED FROM THE SITE ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION IN
          THE USE AND DOWNLOADING OF ANY SUCH CONTENT OR MATERIALS AND USE
          INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES.
          Reference to any products, services, processes, or other information,
          by trade name, trademark, manufacturer, supplier, or otherwise does
          not constitute or imply endorsement, sponsorship, or recommendation
          thereof, or any affiliation therewith, by KreatAR.
        </Typography>

        <Typography className="indent" paragraph>
          4.7 LIMITATION ON LIABILITY EXCEPT IN JURISDICTIONS WHERE SUCH
          PROVISIONS ARE RESTRICTED, IN NO EVENT WILL KreatAR OR ITS DIRECTORS,
          EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY
          INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
          DAMAGES, INCLUDING WITHOUT LIMITATION FOR ANY LOST PROFITS OR LOST
          DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES
          THAT MAY ARISE FROM YOUR PURCHASE OR USE OF ANY PRODUCTS OR SERVICES
          ON THE KreatAR SITES, EVEN IF KreatAR IS AWARE OR HAS BEEN ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
          CONTRARY CONTAINED HEREIN, KreatAR'S AGGREGATE LIABILITY TO YOU FOR
          ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL
          AT ALL TIMES BE LIMITED TO THE AMOUNT ACTUALLY PAID BY YOU TO KreatAR
          FOR THE PURCHASE OR USE OF ANY PRODUCT OR SERVICE ON THE KreatAR
          SITES, BUT IN NO CASE WILL KreatAR'S TOTAL LIABILITY TO YOU EXCEED
          \$200.00. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
          WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS,
          OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
          RIGHTS.
        </Typography>

        <Typography className="indent" paragraph>
          4.8 GOVERNING LAW; VENUE AND JURISDICTION You agree that the laws of
          the State of New York, without regard to principles of conflict of
          laws, will govern your use and purchase of products and services and
          these Terms of Sale, and any dispute of any sort that might arise
          between you and KreatAR or any of our affiliates. With respect to any
          disputes or claims not subject to arbitration (as set forth below),
          you agree not to commence or prosecute any action in connection
          therewith other than in the state and federal courts located in the
          Southern District of New York, NY, and you hereby consent to, and
          waive all defenses of lack of personal jurisdiction and forum non
          conveniens with respect to, venue and jurisdiction in the state and
          federal courts located in the Southern District of New York, NY
        </Typography>

        <Typography className="indent" paragraph>
          4.9 ARBITRATION YOU AND KreatAR AGREE THAT THE SOLE AND EXCLUSIVE
          FORUM AND REMEDY FOR ANY AND ALL DISPUTES AND CLAIMS RELATING IN ANY
          WAY TO OR ARISING OUT OF THESE TERMS OF SALE OR THE SALE OF ANY
          PRODUCT OR SERVICE BY KreatAR SHALL BE FINAL AND BINDING ARBITRATION,
          except that, to the extent that either party has in any manner
          infringed upon or violated or threatened to infringe upon or violate
          the other party's patent, copyright, trademark or trade secret rights,
          such other party may seek injunctive or other appropriate relief.
        </Typography>

        <Typography className="indent" paragraph>
          Arbitration under this Agreement shall be conducted by the American
          Arbitration Association (the "AAA") under its Commercial Arbitration
          Rules and, in the case of consumer disputes, the AAA's Supplementary
          Procedures for Consumer Related Disputes (the "AAA Consumer Rules")
          (collectively the "AAA Rules"). The location of the arbitration and
          the allocation of costs and fees for such arbitration shall be
          determined in accordance with such AAA Rules and shall be subject to
          the limitations provided for in the AAA Consumer Rules (for consumer
          disputes). The arbitrator's award shall be binding and may be entered
          as a judgment in any court of competent jurisdiction.
        </Typography>

        <Typography className="indent" paragraph>
          To the fullest extent permitted by applicable law, NO ARBITRATION OR
          CLAIM UNDER THESE TERMS OF SALE SHALL BE JOINED TO ANY OTHER
          ARBITRATION OR CLAIM, INCLUDING ANY ARBITRATION OR CLAIM INVOLVING ANY
          OTHER CURRENT OR FORMER USER OF THE SITE OR ANY KreatAR PRODUCTS OR
          SERVICES, AND NO CLASS ARBITRATION PROCEEDINGS SHALL BE PERMITTED. In
          no event shall any claim, action, or proceeding by you related in any
          way to any products or services purchased by you on or through any of
          the KreatAR Sites be instituted more than one (1) year after the cause
          of action arose.
        </Typography>

        <Typography className="indent" paragraph>
          4.10 INDEMNITY You agree to indemnify and hold KreatAR, its
          subsidiaries and affiliates, and each of their directors, officers,
          agents, contractors, partners, and employees, harmless from and
          against any loss, liability, claim, demand, damages, costs and
          expenses, including reasonable attorney's fees, arising out of or in
          connection with your purchase or use of any products or services
          offered on any of the KreatAR Sites, or any violation of these Terms
          of Sale or of any law or the rights of any third party.
        </Typography>

        <Typography className="indent" paragraph>
          4.11 MISCELLANEOUS These Terms of Sale, together with the KreatAR
          Terms of Service, the KreatAR Privacy Policy, and the Legal Notices,
          constitute the entire agreement between you and KreatAR regarding your
          purchase and use of KreatAR's goods and services offered on the
          KreatAR Sites (including, without limitation, the KreatAR Premium
          Content Services), superseding any prior agreements between you and
          KreatAR relating to such purchase and use. The failure of KreatAR to
          exercise or enforce any right or provision of these Terms of Sale
          shall not constitute a waiver of such right or provision in that or
          any other instance. If any provision of this Agreement is held
          invalid, the remainder of this Agreement shall continue in full force
          and effect. If any provision of these Terms of Sale shall be deemed
          unlawful, void, or for any reason unenforceable, then that provision
          shall be deemed severable from these Terms of Sale and shall not
          affect the validity and enforceability of any remaining provisions. In
          the event of a conflict between these Terms of Sale and the KreatAR
          Terms of Service, these Terms of Sale shall be controlling. Questions
          For customer support, please send an email to support@KreatAR.com
        </Typography>

        <Typography variant="h6" paragraph>
          Privacy Policy
        </Typography>
        <Typography paragraph>
          The following Privacy Policy applies to your use of all websites,
          mobile applications, and other products and services owned,
          controlled, operated or hosted (whether now or in the future) by
          KreatAR, LLC, Post Reality and/or its corporate affiliates,
          (collectively the "KreatAR Sites").
        </Typography>
        <Typography paragraph>Last Updated: July 8, 2019</Typography>

        <Typography paragraph>
          This Privacy Policy sets forth certain terms and conditions of the
          legal contract between you and KreatAR, LLC with respect to your use
          of the KreatAR Sites and KreatAR Services, whether you are a Visitor,
          Registered Member or Paid Subscriber. The other integral parts of this
          agreement are the KreatAR Terms of Service, the KreatAR Terms of Sale
          and the Legal Notices. All capitalized terms used herein that are not
          otherwise defined in this Privacy Policy shall be as defined in the
          KreatAR Terms of Service.
        </Typography>

        <Typography paragraph>
          At KreatAR, LLC we respect your right to privacy and we understand
          that you need to control the uses of your personal information. This
          Privacy Policy details below the measures taken by KreatAR, LLC to
          protect your privacy in connection with your use of the KreatAR Sites.
          However, you understand that the KreatAR Sites and KreatAR Services
          allow, among other things, users to post personal content (e.g.,
          photographs and information about themselves and others for whom they
          have the authority or permission to post photos and information) for
          public review and comment, both in public forums and with private
          messaging. Accordingly, by submitting personal content for public
          posting on any of the KreatAR Sites or through any of the KreatAR
          Services, you hereby waive any and all privacy expectations (including
          the privacy expectations of any other individual who appears in your
          submitted content) with respect to KreatAR's use of such content. If
          you do not wish to have personal content viewable by others, you
          should not submit content for public posting on the KreatAR Sites and
          KreatAR Services.
        </Typography>

        <Typography paragraph>
          KreatAR allows Registered Members and Paid Subscribers to set up
          unique personal profiles either directly with KreatAR or via
          third-party sign-on services (e.g., Facebook Connect). To enrich user
          experience, we request and display some personal information to others
          so as to allow our users to identify each other. Registered Members
          and Paid Subscribers can change their profile information at any time
          and can control how the service communicates with them. By submitting
          personal information on any of the KreatAR Sites or through any of the
          KreatAR Services, you affirmatively consent to this Privacy Policy and
          agree to have such personal information (to the extent disclosed by
          you either directly to KreatAR or via third-party sign on services)
          collected by KreatAR (or its agents or contractors) and transferred to
          and processed in the United States.
        </Typography>

        <Typography paragraph>
          Your California Privacy Rights Effective January 1, 2005, California
          Civil Code Section 1798.83 (known as the "Shine the Light" law)
          provides that, if an individual who is a California resident has
          provided his/her personal information to a business in connection with
          a business relationship that is primarily for personal, family, or
          household purposes, and if that business has within the immediately
          preceding calendar year disclosed such individual's personal
          information to a third party and knows or should have known that such
          third party used the information for its own direct marketing
          purposes, then that business is obligated to disclose in writing to
          such individual upon request, what personal information was shared and
          with whom the information was shared. A business may comply with this
          law by: (1) having EITHER a published privacy policy of not sharing a
          customer's personal information for third-party direct marketing use
          unless the customer has first affirmatively opted in to such sharing
          OR a published privacy policy of not sharing a customer's personal
          information for third-party direct marketing use if the customer has
          opted out to prevent his/her personal information from being shared
          for third-party direct marketing use; AND (2) notifying the customer
          of his/her right to opt out and providing a cost-free means for the
          customer to exercise that right.
        </Typography>

        <Typography paragraph>
          California residents may request an information-sharing disclosure
          from us by emailing your request to{" "}
          <Link href="mailto:support@KreatAR">support@KreatAR</Link>.com or
          writing to us at The Glimpse Group, Inc., Attn: Maydan Rothblum, 800
          Third Avenue, Suite 1701, New York NY 10022. Please note that, under
          the law, we are not required to respond to your request more than once
          in a calendar year, nor are we required to respond to any requests
          that are not sent to the above-designated email or mailing address.
        </Typography>

        <Typography paragraph>
          Do Not Track Disclosure: The KreatAR Sites do not currently respond to
          "Do Not Track" signals of Web browsers. However, should the industry
          establish uniform technological standards for recognizing and
          interpreting "Do Not Track" signals, the KreatAR Sites would be open
          to following such industry standards.
        </Typography>

        <Typography paragraph>
          Information Collection and Use One of our many goals at KreatAR is to
          provide you with the best possible experience on our sites and
          applications. Through the information that we collect from you, we are
          able to deliver an awesome one of a kind user experience. KreatAR
          collects user submitted information including but not limited to name,
          email address, and age to authenticate users and to send notifications
          to those users relating to the KreatAR Sites and KreatAR Services.
          KreatAR may also collect other data either directly or via third-party
          sign-on services (e.g., Facebook Connect), including but not limited
          to: personal interests, gender, age, education, and occupation, in
          order to enrich the user experience.
        </Typography>

        <Typography paragraph>
          KreatAR also logs non-personally-identifiable information such as IP
          address, aggregate user data, and browser type, from users and
          visitors to the site. This data is used to manage the website, track
          usage and improve the website services. This
          non-personally-identifiable information may be shared with
          third-parties to provide more relevant services and advertisements to
          users. User IP addresses may be recorded for security and monitoring
          purposes. User account information, including pictures and usernames,
          may also be collected and displayed in order to enrich the user
          experience and facilitate user interaction on the KreatAR Sites and in
          email communications. Email addresses are primarily used for the
          purpose of sending notifications related to the service. With the
          exception of notifying friends about content the user has created, a
          user's email address is not shared or publicly displayed on the
          KreatAR Sites or within the KreatAR service. Users' full names, to the
          extent collected and stored, are never directly revealed to other
          users, unless authorized by the user.
        </Typography>

        <Typography paragraph>
          We use the email address you provide upon registration to communicate
          with you regarding your account, your purchases and/or other
          transactions with KreatAR, changes in our Terms of Service, Terms of
          Sale, and/or Privacy Policy, and other matters relating to your
          account with KreatAR. It also enables us to send you announcement
          emails about our new content, features, services, products,
          promotions, etc. You may opt out of receiving announcement emails from
          us at any time by following the opt-out link contained in an
          announcement email you previously received or by logging into the "My
          Account" section of the KreatAR Site(s) where you have an account and
          changing your email preferences in the account settings.
        </Typography>

        <Typography paragraph>
          From time to time, KreatAR and one or more of its partners may
          co-sponsor a promotion, sweepstake or contest or may offer a
          co-branded product or service on the KreatAR Sites (each, a "Joint
          Offering"). Users may be asked to provide personal information such as
          name, email address, home address, etc. or to answer questions in
          order to participate in a Joint Offering, which personal information
          may be shared by KreatAR with its partners involved in such Joint
          Offering, and such partners may use the information for its own
          advertising and marketing purposes. Also, we may transfer personal
          information to certain ad partners that you have explicitly requested
          to receive information from. It will be clear at the point of
          collection who is collecting the personal information and whose
          privacy statement will apply. All in all, we urge you to think
          carefully before disclosing any personal information or User Content
          with KreatAR that you deem private and to take the necessary measures
          to keep your information secure.
        </Typography>

        <Typography paragraph>
          Use of Cookies & Similar Tracking Technologies We may utilize
          technologies (such as "cookies") to help us collect usage data and
          store member preferences. A "cookie" is a small amount of data that is
          sent to your browser from an Internet server and stored in your
          computer. KreatAR uses cookies (including Flash cookies) to store user
          preferences, account status, traffic origination, and to record
          session information, for purposes including ensuring that users are
          not repeatedly offered the same advertisements and to customize
          newsletter, advertising, and Web page content based on browser type
          and user profile information. You may be able to configure your
          browser to accept or reject all or some cookies (other than Flash
          cookies), or notify you when a cookie (other than a Flash cookie) is
          set - each browser is different, so check the "Help" menu of your
          browser to learn how to change your cookie preferences - however, you
          must enable cookies from KreatAR in order to use most functions on the
          KreatAR Sites. For more information about Flash cookies and how to
          remove them from your computer, please see the paragraph below
          entitled "SPECIAL NOTE - Flash Cookies."
        </Typography>

        <Typography paragraph>
          KreatAR may implement several Google Analytics features that support
          Display Advertising. You may opt out of Google Analytics for Display
          Advertising and customize Google Display Network ads using the Ads
          Settings or by installing the Google Analytics opt-out browser add-on.
          KreatAR utilizes "Remarketing with Google Analytics" to advertise
          online. In addition, third-party vendors, such as Google, show these
          ads on sites across the Internet and KreatAR and third-party vendors
          use both first-party and third-party cookies to inform, optimize, and
          serve ads based on user visits to the KreatAR site. KreatAR also
          utilizes "Google Display Network Impression Reporting", which uses
          both first-party and third-party cookies to report how ad impressions,
          uses of ad services, and interactions with the ad impressions and ad
          services are related to user visits to the KreatAR sites. KreatAR also
          utilizes "Demographics and Interest Reporting", which collects data
          based on user demographics and interests, including but not limited
          to: age, gender, affinity category behavior, and other category
          behavior. Data collected from the Google Analytics Demographics and
          Interest Reporting function is used in order to enrich the user
          experience and provide more relevant services and advertisements to
          users. Some KreatAR products may utilize MobileAppTracking™, a
          HasOffers Analytics tracking service which collects non-personally
          identifiable user data including but not limited to: platform,
          timestamp, locale, time zone, page views, and IP address. KreatAR uses
          this service to collect and compare information about the user base
          and user visits. You are free to opt out of this data collection
          service by going to HasOffers Analytics' End User Opt-Out page at{" "}
          <Link href="https://www.optoutmobile.com/optout">
            https://www.optoutmobile.com/optout
          </Link>
        </Typography>

        <Typography paragraph>
          In addition, KreatAR may use so-called "pixel tags," "web beacons,"
          "web bugs," "clear GIFs," etc. (collectively "Web Beacons") to collect
          information about your visits to the KreatAR Sites. These are small
          electronic images embedded in web content (including online ads) and
          email messages and are ordinarily not visible to users. Like cookies,
          Web Beacons enable us to track pages and content (including ads)
          accessed and viewed by users on the KreatAR Sites. Also, when we send
          HTML-formatted (as opposed to plain text) emails to our users, Web
          Beacons may be embedded in such emails to allow us to monitor
          readership levels so that we can identify aggregate trends and
          individual usage to provide our users with more relevant content or
          offers. Web beacons in emails may recognize activities such as when an
          email was opened, how many times an email was forwarded, which links
          in the email were clicked on, etc. Web Beacons cannot be declined when
          delivered via a regular web page. However, Web Beacons can be refused
          when delivered via email. If you do not wish to receive our Web
          Beacons via email, you will need to disable HTML images or refuse HTML
          (select Text only) emails via your email software.
        </Typography>

        <Typography paragraph>
          Please note that KreatAR may allow thirty-party advertisers (including
          those that are serving their own ads on the KreatAR Sites, as well as
          those so-called "network advertisers" or "ad networks" who are serving
          and managing ads for others) to set and use their own cookies
          (including Flash cookies) and/or Web Beacons on your computer when you
          visit the KreatAR Sites. These cookies and Web Beacons allow
          third-party advertisers to recognize your computer each time they send
          you an online advertisement and to compile information about where
          you, or others who are using your computer, saw their advertisements
          and determine which ads are clicked on. The information so compiled
          allows third-party advertisers to deliver targeted advertisements that
          they believe will be of most interest to you. Note, however, that we
          do not show these types of advertisements to our children users or
          track children users for targeted advertising purposes.
        </Typography>

        <Typography paragraph>
          Use of cookies (including Flash cookies) and Web Beacons by
          third-party advertisers is governed by the privacy policies of those
          advertisers - not the KreatAR Privacy Policy. Please note that KreatAR
          has no control over what information is collected by third-party
          advertisers or how they may use the information. The privacy policies
          and data practices of such third-party advertisers may significantly
          differ from those of KreatAR, and KreatAR makes no representation or
          warranty whatsoever about third-party advertisers' data policies and
          practices. We recommend that you read the privacy policies for the
          other third-party advertisers if you have any concerns about their use
          of your personal information or data collection on you. For more
          information regarding the data practices of some of these third-party
          advertisers and how to opt out of their use of your information,
          please visit:{" "}
          <Link href="http://www.networkadvertising.org/choices/#completed">
            http://www.networkadvertising.org/choices/#completed
          </Link>
        </Typography>

        <Typography paragraph>
          The KreatAR Sites may contain links to and/or enables certain
          third-party Internet functionalities to enhance your online
          experience, including, without limitation, social plug-ins, tools and
          APIs. Prior to using any third-party functionalities (e.g., Facebook
          Connect) on the KreatAR Sites, you should consult the privacy notices
          of the third-party providers of such functionalities (e.g., Facebook).
          Once again, we have no control over what information is collected by
          such third parties, or how they may use the information. The privacy
          policies and data practices of such third parties may significantly
          differ from ours, and we make no representation or warranty whatsoever
          about their data policies and practices. Your communications and
          interactions with such third parties are solely between you and them,
          and are at your own risk.
        </Typography>

        <Typography paragraph>
          SPECIAL NOTE - Flash Cookies: Flash cookies (also known as "local
          shared objects" or "LSOs") cannot be rejected, disabled, turned off,
          opted out, or deleted in the same way as regular cookies such as HTML
          cookies. Flash cookies may remain on your computer even after you have
          rejected, disabled, opted out or deleted regular cookies. For
          information on how to manage and remove Flash cookies, please visit:
        </Typography>

        <Typography className="indent" paragraph>
          <Link href="http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html">
            http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
          </Link>
        </Typography>
        <Typography className="indent" paragraph>
          <Link href="http://epic.org/privacy/cookies/flash.html">
            http://epic.org/privacy/cookies/flash.html
          </Link>
        </Typography>
        <Typography className="indent" paragraph>
          <Link href="http://www.aboutcookies.org/Default.aspx?page=2">
            http://www.aboutcookies.org/Default.aspx?page=2
          </Link>
        </Typography>
        <Typography className="indent" paragraph>
          <Link href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
            http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
          </Link>
        </Typography>
        <Typography paragraph>
          Please note that, in addition to KreatAR's Flash cookies, third-party
          advertisers may set and use their own Flash cookies for user tracking
          purposes when serving and managing ads on the KreatAR Sites.
        </Typography>

        <Typography paragraph>
          Invitations and Other Communications to Non-users KreatAR users can
          send content to friends via our system and invite friends to join the
          service via third-party single sign-on services (e.g., Facebook
          Connect). KreatAR may store the email addresses that users provide so
          that KreatAR can send notifications authorized by users and related
          reminders to such email addresses. KreatAR does not sell these email
          addresses or use them to send any other communication besides those
          initiated by users and invitation reminders initiated by KreatAR (one
          (1) per email address). Recipients of emails from KreatAR may contact
          KreatAR to request the removal of their information from our database
          by clicking on the "Opt-Out" Link at the bottom of our emails.
        </Typography>

        <Typography paragraph>
          You may prevent KreatAR messages from being sent to any email address
          you control by sending an email request to support@KreatAR.com or by
          visiting and adjusting your email preferences in My Account. Please
          note that the email must come from the account you wish to block.
        </Typography>

        <Typography paragraph>
          Service Providers We rely on certain third-party services for the
          operation of the KreatAR Sites, such as credit card payment
          processing, order processing, fulfillment and shipment, data storage
          and security, etc. The security of your personal information is
          important to us. Please keep in mind that, to the extent a third-party
          service provider needs to access your information in order to perform
          services to us, we share your information with that provider. However,
          we do require that any third-party service providers limit their use
          of your information solely to providing services to us and that they
          maintain the confidentiality, security, and integrity of your
          information and not make unauthorized use or disclosure of the
          information.
        </Typography>

        <Typography paragraph>
          For example, if you purchase a product from the KreatAR online store,
          we use a third-party vendor to pack and ship your order. Our
          fulfillment vendor will be given access to your full name and shipping
          address solely for the purpose of shipping your order. We require our
          fulfillment vendor not to retain, share, store or use your information
          for any other purpose.
        </Typography>

        <Typography variant="h6" paragraph>
          Third-Party Sites
        </Typography>

        <Typography paragraph>
          The KreatAR Sites may contain links to third-party sites. Unless
          expressly otherwise stated by KreatAR, KreatAR is not responsible for
          the privacy policies and/or practices of linked third-party sites.
          When you leave a KreatAR Site and go to a third-party site, our
          Privacy Policy will no longer apply, and any information collected
          from or about you on the third-party site will be governed by the
          privacy policy of that third party. Third-party sites' privacy
          policies and data practices may be substantially different from those
          of KreatAR. They may send their own cookies, web beacons, etc. to your
          computer or mobile device and may collect data about you and make use
          of the data in ways that we would not. You access such third-party
          sites entirely at your own risk. You should always read the privacy
          policy of a third-party site and use caution before disclosing any
          personal information on that site.
        </Typography>

        <Typography paragraph>
          For example, when you purchase KreatAR Services or products from a
          third-party app store (e.g., Apple/iTunes), your purchase is with the
          third-party app store and not with KreatAR, and accordingly, any
          personal information you submit to the third-party app store in
          connection with the purchase is subject to and governed by the privacy
          policy of the third-party app store and not KreatAR's.
        </Typography>

        <Typography paragraph>
          Public Communication Please be aware that whenever you voluntarily
          post information on the KreatAR Sites that that information can be
          accessed by the public and can in turn be used by those people to send
          you unsolicited communications. We are not responsible for the
          personally identifiable information you choose to submit in these
          forums. We do not currently make available public forums to our
          children users.
        </Typography>

        <Typography paragraph>
          Correcting/Updating or Removing Information KreatAR users may modify
          or remove any of their personal information at any time by logging
          into their account and accessing the "My Account" section. To receive
          notifications on updates or changes to the KreatAR Sites or KreatAR
          Services, we ask that you keep your account updated with most relevant
          personal information (i.e. current email address). Email
          Choice/Opt-out Registered Members and Paid Subscribers may choose to
          no longer receive updates or notifications by managing their email
          preferences in the "My Account" section of any of the KreatAR Sites.
          All notification emails and KreatAR newsletters include instructions
          for opting-out of those communications in the future.
        </Typography>

        <Typography paragraph>
          Security We at KreatAR want to provide you with the best and most
          secure customer experience possible. KreatAR user accounts are secured
          by user-created passwords and, in some cases, third-party sign-on
          services (e.g., Facebook Connect). KreatAR takes precautions to ensure
          that user account information is kept private. We use commercially
          reasonable measures to protect user information that is stored within
          our database, and we restrict access to user information to those
          employees who need access to perform their job functions, such as our
          customer service personnel and technical staff. However, we do not
          guarantee that these efforts will completely protect user account
          information. Unauthorized entry or use, hardware or software failure,
          and other factors may compromise the security of user information at
          any time. We will endeavor to notify users in the event that the
          security of their personal information is breached or compromised. By
          submitting personal information on any of the KreatAR Sites, you
          expressly consent to receiving our notification regarding any breach
          or compromise, or suspected breach or compromise, of the security of
          your personal information, by email or postal mail, as we deem
          appropriate, and as required by law. For any additional information
          about the security measures we use on KreatAR, please contact us at
          support@KreatAR.com.
        </Typography>

        <Typography paragraph>
          We urge you to keep your username, password, and other account access
          information in a safe place and not to divulge it to anyone. Also,
          remember to sign off your account and close your browser window when
          you have finished your visit to a KreatAR Site. This is to ensure that
          others cannot access your account, especially if you are sharing a
          computer with someone else or are using a computer in a public place
          such as a library or an internet cafe.
        </Typography>

        <Typography paragraph>
          Sharing and Disclosure of Information KreatAR Collects Except as
          otherwise described in this privacy statement, KreatAR will not
          disclose personal information to any third party unless we believe
          that disclosure is necessary: (1) to comply with applicable law or to
          respond to a subpoena, court order, search warrant or other legal
          process received by KreatAR, whether or not a response is required by
          applicable law; (2) to protect or enforce our rights or the rights of
          a third party; (3) to protect the safety of members of the public and
          our users; or (4) to respond to an emergency. KreatAR reserves the
          right to transfer personal information to a successor in interest that
          acquires rights to that information as a result of a sale of KreatAR
          or substantially all of KreatAR's assets to that successor in interest
          or of a merger between KreatAR and such successor in interest. KreatAR
          also reserves the right to disclose personal information as necessary
          to enable third-party service providers to support and maintain the
          operation of the KreatAR Sites and KreatAR's services. You will be
          notified via e-mail of any such change in ownership or control of your
          personal information. For more information see the "Changes in Our
          Privacy Policy" section below.
        </Typography>

        <Typography paragraph>
          Children's Privacy KreatAR is committed to protecting the privacy
          needs of children and we encourage parents and guardians to take an
          active role in their children's online activities and interests. The
          KreatAR Sites and KreatAR Service are not intended for use by children
          under the age of 13 without parental supervision. KreatAR does not
          knowingly collect personal information from children under the age of
          13. Users must be 13 or older to be eligible to register on the
          KreatAR Sites. Some of the KreatAR Sites and KreatAR Services, such as
          StoryBots.com, are designed for parents and their young children. We
          want parents to feel confident about their child's activity while
          using our services and have full control over their child's activity
          on our sites. Children can only access and use such sites and services
          with the participation and supervision of their parents. Because of
          the young age of our children users, only parents are allowed to
          create accounts and profiles and to upload personal content on such
          sites and services. KreatAR does not knowingly collect any personal
          information directly from children on such sites and services. We also
          take certain measures to prevent children from submitting personal
          information to us or from accessing features that may collect or
          publish such information. If you wish to review or delete any profile
          information that you have provided us regarding a child, or ask that
          we stop using such information, you may do so at any time through the
          settings area of your account or by contacting us using the
          information below. For more information about keeping your child's
          privacy safe, please visit{" "}
          <Link href="http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online">
            http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online
          </Link>
          .
        </Typography>

        <Typography paragraph>
          Changes in Our Privacy Policy This Privacy Policy is subject to change
          by KreatAR from time to time. If we make material changes (including
          material changes in the way we use or share user personal information,
          such as using information for purposes materially different from those
          disclosed in this Privacy Policy), we will post the changes on the
          KreatAR Site(s) affected by such changes and notify Registered Members
          and Paid Subscribers via email. Also, we may ask Registered Members
          and Paid Subscribers to review and consent to the changes at the time
          of their next account log-in. By continuing to use the KreatAR Site(s)
          affected by such changes after such notice and consent, you agree to
          be bound by the changes to this Privacy Policy. Please check our
          Privacy Policy regularly for updates to our policies and practices.
        </Typography>

        <Typography paragraph>
          Contacting the Web Site If you have any questions about this Privacy
          Policy, the data practices of any of the KreatAR Sites, or your
          dealings with any of the KreatAR Sites, please contact us via email at
          support@KreatAR.com, or via mail atv The Glimpse Group, Inc., Attn:
          Maydan Rothblum, 800 Third Avenue, Suite 1701, New York NY 10022.
        </Typography>

        <Typography variant="h6" paragraph>
          Legal Notices
        </Typography>
        <Typography paragraph>
          The following Legal Notices apply to your use of all websites, mobile
          applications, and other products and services owned, controlled,
          operated or hosted (whether now or in the future) by KreatAR, LLC,
          Post Reality and/or its corporate affiliates, (collectively the
          "KreatAR Sites").
        </Typography>

        <Typography paragraph>
          Copyright & Trademark Notice Product names, logos, designs, titles,
          words or phrases used on any KreatAR Sites products or services are
          owned by KreatAR, LLC, its licensors, or other entities. Such
          trademarks, service marks and trade names may be registered in the
          United States and internationally.
        </Typography>

        <Typography paragraph>
          Software Any software made available to you via download on any of the
          KreatAR Sites is copyrighted by KreatAR, LLC and/or our suppliers. Use
          of the software is governed by the Terms of Use for this site and the
          end-user license agreement, if any, provided in connection with the
          software.
        </Typography>

        <Typography paragraph>
          Government Users Any use, duplication, or disclosure by the United
          States Government is subject to the restrictions set forth in DFARS
          252.227-7013(c)(1)(ii) and FAR 52.227-19.
        </Typography>

        <Typography paragraph>
          Notice and Procedure For Making Claims of Copyright Infringement
          "KreatAR Content" means any and all Content (excluding User Materials)
          made available on the KreatAR Sites and/or through the KreatAR
          Services, including Content owned or controlled by KreatAR and/or its
          corporate affiliates, as well as Content licensed or otherwise
          provided to KreatAR by third parties for use, deployment, display,
          performance and/or distribution via the KreatAR Sites. "User
          Materials" means Content which Users upload, submit, distribute or
          otherwise make available via the KreatAR Sites and/or through the
          KreatAR Services, but excluding any and all KreatAR Content. KreatAR,
          LLC respects the intellectual property rights of others and expects
          all Users to do the same. For claims of copyright infringement
          relating to KreatAR Content and/or User Materials, KreatAR, LLC will
          investigate notices of copyright infringement and take appropriate
          actions under the Digital Millennium Copyright Act, Title 17, United
          States Code, Section 512(c)(2). Pursuant to the requirements of the
          Digital Millennium Copyright Act, Title 17, United States Code,
          Section 512(c)(3), written notification of claimed copyright
          infringement must be submitted via U.S. registered mail to our
          Designated Agent as follows: Copyright Complaints The Glimpse Group,
          Inc Attn: Maydan Rothblum 800 Third Avenue, Suite 1701 New York, NY
          10022
        </Typography>

        <Typography paragraph>
          To be effective, the Notification must include the following:
        </Typography>

        <Typography className="indent" paragraph>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </Typography>
        <Typography className="indent" paragraph>
          Identification of the copyrighted work claimed to have been infringed,
          or if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site;
        </Typography>
        <Typography className="indent" paragraph>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit the service provider to locate the material;
        </Typography>
        <Typography className="indent" paragraph>
          Information reasonably sufficient to permit the service provider to
          contact the complaining party, such as an address, telephone number,
          and if available, an electronic mail address at which the complaining
          party may be contacted;
        </Typography>
        <Typography className="indent" paragraph>
          A statement that the complaining party has a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law;
        </Typography>
        <Typography className="indent" paragraph>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </Typography>
        <Typography className="indent" paragraph>
          Counter-Notification to Claimed Copyright Infringement Pursuant to
          Sections 512(g)(2) and (3) of the DMCA, if a claim of copyright
          infringement has been asserted against you, you may elect to make a
          counter-notification with the Designated Agent identified above. Such
          counter-notification must contain the following information:
        </Typography>

        <Typography className="indent" paragraph>
          Your physical or electronic signature;
        </Typography>
        <Typography className="indent" paragraph>
          Identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or access to it was disabled;
        </Typography>
        <Typography className="indent" paragraph>
          A statement by you under penalty of perjury that you have a good faith
          belief that the material was removed or disabled as a result of
          mistake or misidentification; and
        </Typography>
        <Typography className="indent" paragraph>
          Your name, address, and telephone number, and a statement that you
          consent to the jurisdiction of the Federal District Court for the
          federal district in which you are located (or Los Angeles County,
          State of California, if you reside outside of the U.S.), and that you
          will accept service of process from the person who filed the notice of
          copyright infringement or an agent of such person.
        </Typography>
        <Typography className="indent" paragraph>
          If KreatAR, LLC receives a valid counter-notification, it may
          reinstate the removed or disabled material in accordance with Section
          512(g)(2) of the DMCA. Liability for Misrepresentation under the DMCA
          Please note that, under Section 512(f) of the DMCA, any person who
          knowingly materially misrepresents
        </Typography>

        <Typography className="indent" paragraph>
          that material or activity is infringing, or
        </Typography>
        <Typography className="indent" paragraph>
          that material or activity was removed or disabled by mistake or
          misidentification
        </Typography>
        <Typography className="indent" paragraph>
          will be liable for damages, including costs and attorneys' fees,
          incurred by the alleged infringer, by any copyright owner or copyright
          owner's authorized licensee, or by a service provider, who is injured
          by such misrepresentation, as the result of the service provider
          relying upon such misrepresentation in removing or disabling access to
          the material or activity claimed to be infringing, or in replacing the
          removed material or ceasing to disable access to it. Accordingly, if
          you are not sure whether certain material or activity infringes your
          or others' copyrights, please consult with a copyright attorney first.
        </Typography>

        <Typography paragraph>
          Repeat Infringers For any Users who KreatAR, LLC determines, in its
          sole discretion, are repeat infringers of third-party intellectual
          property rights, KreatAR, LLC will terminate their accounts and/or
          memberships, as the case may be, and prohibit them from creating new
          accounts with KreatAR, LLC.
        </Typography>

        <Typography
          id="cookie-policy"
          variant="h4"
          paragraph
          style={{ textTransform: "none" }}
        >
          Cookie Policy
        </Typography>
        <Typography paragraph style={{ fontWeight: 500 }}>
          Last updated 26 January 2022
        </Typography>
        <Typography paragraph>
          Post Reality respects your privacy. We want to ensure that you have a
          full understanding of how information about you is collected when you
          are using our service, and thus can make informed decisions about your
          privacy. In order to enable us to reach this goal, we have prepared
          this cookie policy, the purpose of which is to list the cookies we use
          in our service, and to explain what the cookies do and what kind of
          information they collect.
        </Typography>
        <Typography paragraph>
          We have also included information about cookies set by third parties
          (“Third Party Cookies”). Given that these relate to third party
          services, we cannot guarantee the completeness or accuracy of the
          list, but we can say that we have done our best to ensure the list is
          as accurate as possible at the time this policy was prepared.
          Nevertheless, we strongly recommend that you consult the third party
          websites listed in the cookie descriptions to find out more about the
          third party cookies in question.
        </Typography>
        <Typography paragraph>
          Our aim is to ensure that this policy is continuously updated to
          reflect any material changes in our use of cookies. If we change this
          policy, we will post an updated version on our site at postreality.io.
        </Typography>
        <Typography paragraph>
          For more information about how to manage and delete cookies, visit
          aboutcookies.org.
        </Typography>
        <Typography paragraph style={{ fontWeight: 500 }}>
          Cookies set by Post Reality
        </Typography>
        <Typography color="textSecondary">_ga</Typography>
        <Typography paragraph>
          This cookie is used to identify unique users.
        </Typography>
        <Typography color="textSecondary">session</Typography>
        <Typography paragraph>
          The server creates a “session ID” which is a randomly generated number
          that temporarily stores the session cookie
        </Typography>
        <Typography color="textSecondary">pr-cookie-consent</Typography>
        <Typography paragraph>
          This cookie consent is for end-users in which they decide whether to
          allow cookies and trackers to be activated to process their personal
          data
        </Typography>
        <Typography paragraph style={{ fontWeight: 500 }}>
          *Other cookies set by XRP (aka XR-Platform, Post Reality's backend):
        </Typography>
        <Typography color="textSecondary">
          CloudFront-Policy, CloudFront-Signature, CloudFront-Key-Pair-Id
        </Typography>
        <Typography paragraph>
          These are the Cloudfront cookies explicitly set by xrp related to
          permissions around protected assets.
        </Typography>
        <Typography color="textSecondary">comment_[UUID]</Typography>
        <Typography paragraph>
          This cookie is related to permissions around a comment by a
          potentially anonymous user
        </Typography>
        <Typography color="textSecondary">comment_vote_[UUID]</Typography>
        <Typography paragraph>
          This cookie is related to permissions around a comment_vote by a
          potentially anonymous user
        </Typography>
        <Typography color="textSecondary">experience_like_[UUID]</Typography>
        <Typography paragraph>
          This cookie is related to permissions around an experience_like by a
          potentially anonymous user
        </Typography>
        <Typography paragraph style={{ fontWeight: 500 }}>
          Cookies set by 3rd parties
        </Typography>
        <Typography color="textSecondary">
          _ga, _gid, SAPISID, SSID, HSID, SID, SIDCC, APISID, 1P_JAR, NID,
          SEARCH_SAMESITE, OGPC
        </Typography>
        <Typography paragraph>
          These are cookies set by Google Analytics and Google, which are
          services we use to gain a better understanding of how people use our
          service. You can read more about Google's privacy policy here:{" "}
          <Link href="https://policies.google.com/faq?hl=None">
            https://policies.google.com/faq?hl=None
          </Link>
        </Typography>
        <Typography color="textSecondary">__stripe_mid</Typography>
        <Typography paragraph>
          A number of cookies set by our payment partner, Stripe, for fraud
          detection purposes.
        </Typography>
        <Typography style={{ fontWeight: 500 }}>
          Setting Your Browser to Reject Cookies, or 'Do not track' requests
        </Typography>
        <Typography paragraph>
          At this time, Post Reality does not respond to “do not track” signals
          across all of our Services. However, you can usually choose to set
          your browser to remove or reject browser cookies before using the
          Service, with the drawback that certain features of the Service may
          not function properly without the aid of cookies.
        </Typography>
      </div>
    </>
  );
};
export default Terms;
